.loginWrapper {
  width: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 2*var(--splayout));
  margin: auto;
}

.loginLogo {
  width: 70%;
}

.textfield input {
  padding: 4px 12px;
  min-height: 30px;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.loginInput {
  background-color: white;
  padding: 14px 12px;
  border-radius: 10px;
  font-size: 14px;
}

.appName {
  position: relative;
  padding-bottom: 6px;
  text-transform: uppercase;
  text-shadow: 0 0 #092b4a70;
}

.appName:after {
  content: '';
  position: absolute;
  background-color: #59b262;
  width: 50px;
  height: 3px;
  bottom: 0;
  right: 50%;
  transform: translateX(50%);
}

.submitBtn{
  min-height: 38px;
}

.materialIcon{
  font-size: 34px;
  background-color: var(--primaryblue);
  border-radius: inherit;
  border: 2px solid white;
  color: white;
  padding: 4px;
}

.materialIconContainer {
  position: relative;
  width: 38px;
  min-height: 38px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid var(--secondarygreen);
  margin-bottom: 6px;
}