/* FLIGHTS FOLDER */
.folderContainer {
  max-width: 310px;
  z-index: 0;
  position: relative;
  margin: auto;
  margin-top: 12px;
  margin-bottom: 24px;
  height: 100%;
}

.folderWrapper {
  --folderBr: 12px;
  font-size: 0.85rem;
  font-weight: 600;
  overflow: visible;
  color: var(--primaryblue);
  width: 100%;
  margin: auto;
  display: block;
  text-decoration: none;
  position: relative;
}

.cardContent {
  margin-top: -10px;
  padding-top: 4px;
  background-color: #fbfbfb;
  border-radius: var(--folderBr);
  box-shadow: 0px 0px 4px #d2d2d2;
  box-shadow: inset 0px 5px 5px 0px #c1c1c1;
}

.cardContent:last-child {
  padding-bottom: 0;
}

.redTop {
  filter: invert(18%) sepia(68%) saturate(3912%) hue-rotate(340deg) brightness(91%) contrast(92%);
}

.blueTop {
  filter: invert(13%) sepia(30%) saturate(2356%) hue-rotate(177deg) brightness(93%) contrast(97%);
}

.greenTop {
  filter: invert(63%) sepia(39%) saturate(535%) hue-rotate(75deg) brightness(90%) contrast(90%);
}

.amberTop {
  filter: invert(79%) sepia(84%) saturate(590%) hue-rotate(328deg) brightness(101%) contrast(96%);
}

.reportIcon {
  /* margin-left: 6px; */
  border-radius: 50%;
  height: 10px;
  width: 10px;
}

.statusPopoverHeader {
  padding: 0.5rem 1.2rem;
}

.statusDesc {
  font-size: 13px;
  font-weight: initial;
  margin-bottom: 10px;
  margin-top: 0;
  display: flex;
  justify-content: space-between;
}

.statusIndicator {
  font-size: 11px;
  font-weight: bold;
  margin-top: 0;
  padding: 1px 12px;
  border-radius: 10px;
  background-color: #ececec;
  color: var(--primaryblue);
  min-width: 75px;
  text-align: center;
}

.statusDesc:last-child {
  margin-bottom: 0;
}

.statusWrapper {
  margin-top: -40px;
  margin-bottom: 60px;
  position: relative;
}

.folderName {
  color: white;
  position: absolute;
  top: clamp(0.65rem, 0.3vw, 1.46rem);
  left: 14px;
  font-weight: bold;
  font-size: clamp(0.95rem, 1vw, 1.06rem);
  line-height: normal;
}

.folderBadgeRed,
.folderBadgeGreen {
  position: absolute;
  top: -4px;
  right: 46%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  height: 20px;
  border-radius: 20px;
  padding: 4px;
  font-size: 0.75rem;
}

.tableNoteCount {
  top: 8px;
  min-width: 16px;
  height: 16px;
  padding: 4px;
  font-size: 0.65rem;
  font-weight: bolder;
}

.w150 {
  width: 150px;
  flex: 1 auto;
}

.w160 {
  width: 160px;
  flex: 1 auto;
  max-width: 180px;
}

.toggleButtonRoot {
  font-weight: bolder;
  text-transform: capitalize;
  border: 2px solid var(--redText);
  color: var(--redText);
  border-radius: 50%;
  margin-left: auto;
  display: flex;
  font-size: 20px;
  padding: 5px;
  max-height: 34px;
  min-width: 34px;
}

.toggleButtonRoot.rejectedFlights,
.toggleButtonRoot:hover {
  background-color: var(--redText);
  color: white;
}

.folderBadgeGreen {
  background-color: white;
  color: var(--primaryblue);
  border: 1px solid var(--primaryblue);
}

.folderBadgeRed {
  color: white;
  background-color: var(--redText);
}

.folderNameSub {
  text-align: right;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: -4px;
}

.progressWrapper {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.forderTopImg {
  min-height: 60px;
}

.iconWrapperOuter {
  /* height: 46px;
  position: relative; */
  margin-right: 16px;
  position: absolute;
  top: -42px;
  right: 0px;
}

.iconWrapper img {
  height: 14px;
}

.folderBadge {
  min-width: 16px;
  height: 16px;
  border-radius: 50%;
  position: absolute;
  right: -6px;
  top: -6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: bold;
}

.flightStatus {
  margin-left: 10px;
  font-size: 14px;
  text-transform: capitalize;
}


.folderNameWrapper {
  display: flex;
  z-index: -1;
  box-shadow: 0px 0px 8px #e8e7e7 inset;
  position: absolute;
  top: -40px;
  left: 0;
  right: 0;
}

.flightReportStatus {
  background-color: #e8e8e8a1;
  border-radius: 10px;
  padding: 1px 12px;
  font-weight: 600;
}

.headerWrapper {
  top: -80px;
}

.addFlightWrapper,
.checkboxFilter,
.selectFilter {
  background-color: var(--greybkg);
}

.checkboxFilter,
.selectFilter {
  padding: 24px;
  border-bottom: 3px solid white;
}

.addFlight {
  background-color: white;
  border-bottom-right-radius: 70px;
  padding-left: var(--splayout);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: -2px;
}

.checkboxFilter {
  border-top-left-radius: 40px;
}

.selectFilter {
  border-radius: 0 22px 0 0;
}

.flightFolderWrapper {
  border-radius: 22px 0 22px 22px;
  padding: 64px 38px;
  padding-bottom: 24px;
  position: relative;
  height: calc(var(--contentBox) - 90px);
  /* overflow: auto; */
}

.flightCheckbox {
  font-size: 8px;
}

.flightCheckboxRoot .flightCheckboxLabel {
  font-size: 0.875rem;
}

.labelfs {
  font-size: 14px;
}

.flightSwitch {
  position: absolute;
  top: 6px;
  right: 50%;
  transform: translateX(50%);
}

.flightTableOuter {
  height: calc(var(--contentBox) - 178px);
  margin-top: -40px;
}

.statusPopover {
  min-width: 280px;
}

.noLogo {
  width: 50px;
  height: 50px;
}

.inlineEl {
  background-color: #e3e3e3;
  color: var(--primaryblue);
  padding: 4px;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  font-size: 10px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: normal;
}

.adhocFlight {
  border: 1px solid var(--primaryblue);
  border-radius: 10px;
  padding: 0px 6px;
  font-size: 10px;
  font-weight: bold;
}

@media screen and (max-width: 1280px) {
  .headerWrapper {
    position: static;
    min-width: 100%;
    width: 100%;
    background-color: var(--greybkg);
    padding: var(--splayout);
    border-radius: 20px;
    margin-bottom: var(--splayout);
  }
}

@media screen and (max-width: 1199px) {
  .flightFolderWrapper {
    border-top-left-radius: 0px;
  }

  .addFlightWrapper {
    margin-bottom: var(--splayout);
  }

  .addFlight {
    border-bottom-right-radius: 0px;
    padding-left: 0;
  }
}

@media screen and (max-width: 899px) {
  .checkboxFilter {
    border-top-right-radius: 40px;
  }

  .selectFilter {
    border-top-right-radius: 0;
  }
}

@media screen and (max-width: 600px) {
  .folderContainer {
    margin: auto;
  }
}

@media screen and (min-width: 1024px) {
  .headerWraperTop {
    position: absolute;
    left: 0;
    right: 0;
    top: 50px;
    margin: auto;
    /* width: clamp(200px, 30%, 350px); */
    min-width: 170px;
    max-width: calc(100% - 360px - 310px - 48px);
  }

  .filterCard {
    padding: 0;
  }

  .filterTitle {
    display: none;
  }

  .tabWrapper>.billingFilterContainer {
    padding-top: 0px;
  }
}


@media screen and (max-width: 1023px) {

  .headerWraperTop {
    position: static;
    background-color: var(--greybkg);
    padding: 0px;
    border-radius: var(--headerbr);
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    margin-bottom: var(--splayout);
  }
}