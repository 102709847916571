.blocks {
  display: contents !important;
}

.tabArrows {
  align-self: center;
}

.tabArrows svg {
  font-size: 3rem;
}

.fJcAround {
  justify-content: space-between;
}

.headerTab {
  margin-bottom: 24px;
}

.whiteListcon {
  max-width: 30px;
}

.notificationIcon {
  height: 30px;
  margin-bottom: 1px;
  max-height: 30px;
  width: auto;
}

@media screen and (min-width: 1201px) {
  .headerTab {
    position: absolute;
    top: 34px;
    right: 0;
    left: 0;
    width: calc(100% - 24px - 24px - 670px);
    max-width: 1020px;
    /* 100% - marginRight - marginLeft - Right/Left Header blue block */
    margin: auto;
  }
}