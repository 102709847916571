/* Document */

.pdf-slider-container {
  min-width: 500px;
  min-height: 410px;
  text-align: center;
}
.attachmentPage canvas {
  margin: auto;
}

.pagination {
  margin: 0 auto;
}

.tsleftArrow,
.tsrightArrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
  z-index: 1;
}

.tsleftArrow {
  left: -14px;
}

.tsrightArrow {
  right: -14px;
}


/* review modal */
.popoverWidth {
  width: 450px;
}

.dot {
  text-align: center;
  border-radius: 50px;
  margin-right: 10px;
  font-weight: 600;
  width: 8px;
  height: 8px;
  display: inline-block;
}

.warning {
  background-color: #cc930b;
}

.approved {
  background-color: var(--secondarygreen);
}

.rejected {
  background-color: var(--redText);
}

.noteDivider:before {
  content: '';
  position: absolute;
  width: 30px;
  height: 2px;
  background-color: #f2f2f2;
  margin: auto;
  bottom: 0px;
  right: 50%;
}

/* progress modal */

.progressCount {
  font-size: 30px;
  font-weight: 600;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.downloadWrapper {
  padding: 50px;
  margin: 10px 0;
  margin-top: 0;
  background-color: #f4f4f4;
  border-radius: 4px;
}

/* history */

.historyTableOuter {
  height: calc(var(--contentBox) - 400px);
}

.historyListText {
  display: flex;
  flex-direction: column-reverse;
}

.historyListItem {
  background-color: #eee;
  padding: 6px 12px;
  border-radius: var(--ip-br);
  margin-bottom: 10px;
  max-width: 330px;
}

.historyWrapper {
  position: absolute;
  left: 24px;
  top: 52px;
  z-index: 2;
}