.uldListTableOuter {
  height: calc(var(--contentBox) - 260px);
  min-height: 200px;
}

.uldContentTableOuter {
  height: 23vh;
  min-height: 190px;
}

.awbsTableOuter {
  min-height: 150px;
}

.historyIcon {
  width: auto;
  min-width: auto;
  line-height: unset;
  padding: 0;
  font-weight: 700;
  font-size: 12px;
  border: 1px solid #092b4a;
  width: 18px;
  height: 18px;
}

.dataGridRoot .dataColumnHeaders {
  overflow: hidden;
  visibility: hidden;
}

/* MOVE ULD TO DIFFERENGT FLIGHT */
.popoverWidth {
  width: 530px;
}

/* LOADING ACCESSORIES */
.tableTextField input {
  width: 60px;
  padding: 4px;
  background-color: white;
  font-size: 12px;
  border-radius: inherit;
}

.loadAccessoriesPopover {
  width: 500px;
}

.accRowOdd {
  border-right: 1px solid #d9d9d9;
  padding-right: 10px;
}

.awbIpTextfield .awbIpRoot .awbIp {
  padding: 6px 8px;
}

.editIcon {
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
}

.editIcon img {
  width: 16px;
}

.lockIcon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.lockIcon>span {
  font-size: 0;
}

.lockIcon img {
  filter: invert(98%) sepia(2%) saturate(0%) hue-rotate(174deg) brightness(100%) contrast(130%);
  opacity: 0.8;
  width: 14px;
}

.tagBtn {
  min-width: 36px;
  min-height: 30px;
  padding: 2px 6px;
  font-size: 12px;
}

.printBtn {
  display: flex;
  flex-direction: column;
  padding: 2px 0 1px 0;
}

.printBtnText {
  line-height: 1;
  font-size: 10px;
  text-transform: uppercase;
}

.balanceIcon {
  margin-top: -2px;
}

.portChip {
  padding: 0px 4px;
}

.circleIcon {
  width: 0.6rem;
}

.lineHeight {
  line-height: 1;
}

.uldContentTable .overlayWrapperInner {
  height: 100px !important;
}

.uldContentTable .virtualScrollerContent {
  height: 120px !important;
}

.helperText {
  margin-bottom: -24px;
  /* white-space: nowrap; */
  padding-left: 4px;
  position: absolute;
}

.containerEnd {
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 14px;
}

.addAwbDetails {
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 4px;
}

.uldIpTextfield .uldIpRoot .uldIp {
  padding: 6px 6px;
}

.uldIpTextfield,
.uldNumber {
  width: clamp(50px, 100px, 100%);
}


.selectedRow :global .MuiDataGrid-cell {
  /* border-top: 1px solid var(--primaryblue);
  border-bottom: 1px solid var(--primaryblue); */
  background-color: #092b4a33;
}

.selectedRow :global .MuiDataGrid-cell:first-child {
  /* border-left: 1px solid var(--primaryblue); */
  border-radius: var(--tbbr) 0 0 var(--tbbr);
}

.selectedRow :global .MuiDataGrid-cell:last-child {
  /* border-right: 1px solid var(--primaryblue); */
  border-radius: 0 var(--tbbr) var(--tbbr) 0;
}

.uldContentTable .uldContentRow .uldContentCell,
.uldContentTable .columnHeaderTitleContainer {
  padding-left: 8px;
  padding-right: 8px;
}

.uldListTable .uldListRow .uldListCell,
.uldListTable .columnHeaderTitleContainer {
  padding-left: 8px;
  padding-right: 8px;
}

.closeIcon>span {
  margin-bottom: 2px;
  display: block;
}

.pcsCell {
  min-width: 40px;
  margin: auto;
  display: block;
}

.infoIcon {
  font-size: 10px;
  font-weight: 700;
  color: var(--primaryblue);
  border-radius: 50%;
  border: 1px solid var(--primaryblue);
  line-height: normal;
  min-width: 18px;
  height: 18px;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.portMonitorPopover {
  width: 300px;
}

/* PDF */
.downloadLink {
  text-decoration: none;
  color: white;
}

@media screen and (max-width: 1536px) {
  .uldListTableOuter {
    height: calc(var(--contentBox) - 450px);
    min-height: 200px;
  }
}