
.downloadIcon {
  font-size: 3.5rem;
  color: var(--secondarygreen);
  border: 1px solid var(--secondarygreen);
  margin: auto;
  display: block;
  margin-bottom: 30px;
  border-radius: 50px;
  padding: 6px;
}

.downloadWrapper {
  padding: 50px;
  margin: 10px 0;
  margin-top: 0;
  background-color: #f4f4f4;
  border-radius: 4px;
}

.downloadBtnWrapper {
  margin-top: 30px;
}

.downloadBtnWrapper button {
  width: 100%;
}
