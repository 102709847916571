.awbTableOuter {
  height: calc(var(--contentBox) - 260px);
}

.days {
  background-color: var(--primaryblue);
  color: white;
  padding: 4px;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  font-size: 10px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

svg.attIcon {
  transform: rotate(-25deg);
  height: 16px;
}

.slider {
  width: calc(100% - 100px);
  margin: auto;
}

.leftArrow,
.rightArrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.leftArrowIcon,
.rightArrowIcon {
  font-size: 4rem;
}

.leftArrow {
  left: -5rem;
}

.rightArrow {
  right: -5rem;
}

.docDetails {
  min-width: 130px;
  color: var(--primaryblue);
  display: inline-block;
  font-weight: bold;
}