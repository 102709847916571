.checkboxWrapper {
  background-color: white;
  padding: 6px 0 6px 10px;
  margin-left: 0;
  border-radius: var(--ip-br);
  justify-content: space-between;
  width: 100%;
}

.disabledChecklist {
  background-color: #e0e0e0;
}

.addTextField {
  width: clamp(100px, 74%, 206px);
  padding-left: 10px;
}

.addTextFieldStats {
  width: 200px;
  padding-left: 10px;
}

.labelStats {
  width: calc(100% - 200px);
}

.statsWrapper{
  max-height: 454px;
}

.overflowWrapper{
  overflow-y: scroll;
  overflow-x: auto;
  padding-right: calc(var(--gap) - 14px); /* gap - scrollbarwidth */
  margin-right: calc(var(--gap) * -1);
}

.overflowWrapper::-webkit-scrollbar-track {
  background-color: var(--greybkg);
}

.overflowWrapper ::-webkit-scrollbar-track {
  background-color: var(--greybkg);
}

.pdfWrapper {
  background-color: white;
  border-radius: 10px;
  padding: 0.8rem 1.3rem;
  width: max-content;
  margin: auto;
}

.pdfWrapper img {
  width: 50px;
}

.attDetails {
  min-width: 100px;
  padding-right: 12px;
  /* width: 50%; */
  flex: 1 0 120px;
}

.delIcon {
  position: absolute;
  right: -1px;
  bottom: 7px;
  float: right;
  width: 14px;
}

.delIcon img {
  width: 100%;
}

.attachmentWrapper {
  max-height: 480px;
  min-height: 210px;
  margin-right: -24px;
  padding-right: 24px;
  overflow: auto;
  scrollbar-width: thin;
}

.finalizeBtn {
  width: 100%;
  max-width: 280px;
}

.tabCount {
  background-color: var(--primaryblue);
  color: var(--greybkg);
  text-shadow: 0 0 white;
  margin: auto;
  padding: 4px;
  min-width: 25px;
  min-height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 12px;
  font-weight: 600;
}

.tabCountLabel {
  font-size: 0.8rem;
}

.tabCountWrapper .tabCount {
  position: relative;
}

.tabCountWrapper:not(:last-child):after,
.tabCountWrapper:not(:first-child)::before {
  content: '';
  position: absolute;
  width: 50%;
  height: 2px;
  background-color: green;
  top: 20px;
}

.tabCountWrapper:after {
  left: 60%;
}

.tabCountWrapper::before {
  right: 60%;
}

.manifestWrapper {
  position: absolute;
  right: 0;
  bottom: 0;
}

.tabFlexContainer {
  border-bottom: 1px solid rgb(192, 192, 192);
}

.reportTab.selectedReportTab {
  border-bottom: 2px solid var(--secondarygreen);
}

.reportTab {
  border-bottom: 2px solid transparent;
  text-transform: capitalize;
  min-height: 30px;
  min-width: 170px;
  font-size: 0.8rem;
}

.notesWrapper {
  /* background-color: #FEFF9C; */
  background-color: #fde96f;
  min-height: 300px;
}

.noteHead {
  min-height: 30px;
}

.checkdetails {
  line-height: 1.5;
  text-wrap: balance;
  width: 100%;
}

.checkdetailsText {
  text-wrap: balance;
}

.centerAlignText {
  width: 80px;
  text-align: center;
}

.noteIcon svg{
  max-height: 18px;
}

.checklistTable .redRow {
  background-color: rgba(255, 0, 0, 0.1) ;
}

.excelWrapper {
  position: absolute;
  right: 24px;
  top: 44px;
  z-index: 2;
}

.excelWrapper img {
  width: 24px;
}

.noteListItem {
  background-color: #0000000f !important;
}

.reportIcon {
  top: 4px;
}

/* history */

.historyTableOuter {
  height: calc(var(--contentBox) - 400px);
}

.historyListText {
  display: flex;
  flex-direction: column-reverse;
}

.historyListItem {
  background-color: #eee;
  padding: 6px 12px;
  border-radius: var(--ip-br);
  margin-bottom: 10px;
}

.historyWrapper {
  position: absolute;
  left: 24px;
  top: 52px;
  z-index: 2;
}

@media screen and (min-width: 1536px) {
  .gridContainer>.gridItem {
    padding-top: 0px;
  }
}

@media screen and (max-width: 1535px) {
  .attachmentWrapper {
    max-height: 500px;
  }
}

@media screen and (max-width: 899px) {
  .addTextField {
    width: 100%;
    padding-left: 0px;
  }
}