.uploadButton {
  display: flex;
  flex-direction: column;
  border: 2px dashed #59b362;
  border-radius: 4px;
  padding: 20px;
  background-color: #ffffff4f;
}

.uploadSvg {
  font-size: 3rem;
  fill: var(--secondarygreen);
  margin: auto;
  margin-bottom: 10px;
  border: 1px solid #59b362;
  border-radius: 50%;
  padding: 2px;
}

label.customFileUploader {
  display: block;
  border: 2px dashed var(--secondarygreen);
  padding: 30px;
  text-align: center; 
  cursor: pointer; 
  border-radius: 10px;
  background-color: #f9f9f9; 
  transition: all 0.3s ease;
}