.airlineTableOuter, .productsTableOuter, .specialsTableOuter {
  height: calc(var(--contentBox) - 106px);
}

.titleWrapper {
  min-height: 64px;
}

.title {
  padding-bottom: 10px;
}