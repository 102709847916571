.blocks {
  display: contents !important;
}

.tabArrows {
  align-self: center;
}

.tabArrows svg {
  font-size: 3rem;
}

.fJcAround {
  justify-content: space-between;
}

.tabheadName {
  background-color: var(--primaryblue);
  padding: 4px 8px;
  color: #e3e3e3;
  border-radius: 4px;
  font-weight: bold;
  font-size: 10px;
  text-shadow: 0 0 white;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 1201px) {
  .docArchiveTabs {
    position: absolute;
    top: 34px;
    width: calc(100% - 24px - 24px - 670px);
    max-width: 780px;
    /* 100% - marginRight - marginLeft - Right/Left Header blue block */
  }
}