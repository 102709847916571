.manifestDetails {
  background-color: white;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 10px;
  width: 100%;
}

.manifestTable th {
  padding: 0px;
  border-bottom: none;
}

.manifestTable input[type="text"] {
  text-align: center;
  text-transform: uppercase;
  padding: 4px 8px;
  font-size: 0.8rem;
  border: none;
  white-space: nowrap;
  color: var(--primaryblue);
  font-weight: 500;
}

input.orgdest[type="text"] {
  width: 40px;
  padding: 4px;
}

.manifestTable input[type="text"]:focus {
  outline: 1px solid var(--primaryblue);
  border-radius: 3px;
}

.manifestTable input.emptyInput[type="text"] {
  border: 1px solid var(--redText);
  border-radius: 4px;
}

.manifestTable input.emptyInput[type="text"]:focus {
  outline: 1px solid var(--redText);
}

input.splInput {
  width: 100px;
  min-width: 100%;
}

td.awbIp{
  width: clamp(130px, 10vw, 150px);
  min-width: 130px;
}

.manifestTable td {
  padding: 2px 8px;
  font-size: 0.8rem;
  border-bottom: none;
  white-space: nowrap;
  color: var(--primaryblue);
  font-weight: 500;
  position: relative;
  min-height: 34px;
}

.manifestTable td:not(:last-child, :nth-last-child(2)):after {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 50%;
  background-color: #eeeeeec4;
}

.smSelect {
  padding-top: 2px;
  padding-bottom: 2px;
  width: 100px;
}

.manifestWrapper {
  max-height: calc(var(--contentBox) - 230px);
  ;
}

/* PDF SLIDER */
.pdfSliderItems {
  padding: 2rem;
}

.doc canvas {
  margin: auto;
}

.showPrint {
  display: none;
}

.hidePrint {
  display: block;
}

.uldWrapper {
  background-color: var(--primaryblue);
  border-radius: 10px 10px 0 0;
  color: white;
  padding: 5px 12px;
  margin-bottom: -6px;
  text-align: center;
  font-weight: initial;
  position: relative;
}

.deleteUld {
  position: absolute;
  right: 28px;
  top: 50%;
  transform: translateY(-51%);
}

.addUldBtn {
  min-width: 150px;
  justify-content: center;
}

.addAwbBtn {
  background-color: white;
  font-size: 12px;
  padding: 4px;
  line-height: normal;
  min-width: 80px;
  margin-left: auto;
  display: block;
}

.manifestTable td.addAwbCell {
  background-color: #80808014;
  padding: 8px;
  border-radius: 6px;
}

.actionIconsLg img {
  width: 18px;
}

.delIcon img {
  width: 14px;
  padding-top: 6px;
}

@media print {
  .showPrint {
    display: block;
  }

  .hidePrint {
    display: none;
  }

  .printTableContainer .printTable {
    border-spacing: 0;
    margin-top: 0;
    padding-right: 0;
  }

  .printTableContainer>.printTable>.printTableHead .printTableCell {
    background-color: transparent;
    color: #252525;
    font-weight: 700;
    border-collapse: separate
  }

  .printTableCell {
    border-bottom: 0;
    border: 1px solid #919191;
    border-right: 0;
    border-bottom: 0;
  }

  .printTableCell:last-of-type {
    border-right: 1px solid #919191;
  }

  .printTable {
    border-bottom: 1px solid #919191;
  }

  .printTableContainer>.printTable>.printTableBody>.printTableRow>.printTableCell:first-of-type,
  .printTableContainer>.printTable>.printTableBody>.printTableRow>.printTableCell:last-of-type {
    border-radius: 0;
  }
}