.badge {
  margin-left: 15px;
}

.addTextField {
  width: clamp(100px, 74%, 173px);
  padding-left: 10px;
  margin-left: auto;
}

.addTextLabel {
  width: calc(100% - 183px);
  white-space: wrap;
}

.docBtn :global .MuiButton-root {
  min-width: 165px;
}

.docBtnOnly :global .MuiButton-root {
  margin-left: auto;
  display: block;
}

.canvasDoc {
  height: 100%;
}

.itsOkText {
  color: green;
  font-size: 12px;
}

.itsNotOkText {
  color: red;
  font-size: 12px;
}

.check svg {
  font-size: 18px;
  margin: auto;
  display: block;
  margin-top: 4px;
}

.canvasDoc canvas {
  margin: auto;
  width: 100% !important;
  height: auto !important;
  max-width: 500px;
}

.docDoc {
  overflow-x: hidden;
}

.docPage {
  min-width: auto !important;
  min-height: auto !important;
  margin-bottom: 20px;
}


.image {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.dateFilter input {
  font-size: 0.65rem !important;
}

.checkBoxxs span {
  font-size: 0.7rem !important;
}

.checkBoxxs span:nth-child(2) {
  margin-left: -6px;
  white-space: nowrap;
}

.docs {
  width: 100%;
  max-width: 500px;
  display: block;
  margin: auto;
}

.docSec {
  width: 410px;
}

.docView {
  width: calc(100% - (2 * 440px));
  scrollbar-width: thin;
}


.h100 {
  height: 100%;
}

.datePicker :global .MuiInputBase-root {
  padding-right: 12px;
}

.datePicker :global .MuiInputBase-root .MuiInputBase-input {
  font-size: 11px;
  padding-left: 10px;
  width: 55px;
  min-width: 55px;
}

.datePicker :global .MuiInputAdornment-root {
  margin-left: -4px;
}

/* .delIcon {
  height: 16px;
  width: 12px;
} */

.delIcon img {
  height: 100%;
  width: 14px;
}

.docRadioWrapper :global .MuiFormControlLabel-label {
  font-size: 12px;
}

.iacName {
  font-size: 11px;
  line-height: normal;
  display: inline-block;
}

.htmlRadio {
  accent-color: var(--primaryblue);
}

.checkWrapper :global .MuiFormControlLabel-label {
  font-size: 12px;
  margin-left: -3px;
  white-space: nowrap;
}

.checkWrapper {
  margin-left: -19px;
}

.thirdPartyCheck .checkLabel {
  font-size: 12px;
  margin-left: -3px;
  white-space: nowrap;
}

.incomingDocHead {
  position: sticky;
  top: calc(-1 * var(--splayout));
  background: var(--greybkg);
  z-index: 1;
  padding: var(--splayout);
  padding-bottom: 0;
  margin: calc(-1 * var(--splayout));
  margin-bottom: 0;
}

.thirdPartyDocWrapper {
  border-bottom: 1px solid var(--grey);
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.smRadioToggle {
  composes: radioToggle from '../../../custom/style.module.css';
  min-width: 76px;
  padding: 2px 8px;
}

.smRadioToggle .smRadioToggleLabel {
  font-size: 0.75rem;
}

.px4 {
  padding-right: 24px;
  padding-left: 24px;
}

.listDocBody,
.viewDocBody,
.verifyDocBody {
  overflow: auto;
  margin-right: -24px;
  margin-left: -24px;
  scrollbar-width: thin;
}

.listDocBody {
  min-height: 200px;
  max-height: calc(100vh - 390px);
}

.viewDocBody {
  min-height: 200px;
  max-height: calc(100vh - 380px);
}

.verifyDocBody {
  min-height: 200px;
  max-height: calc(100vh - 310px);
}

.incomingDocH100 {
  height: 100%;
}

.placeholderText {
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 40px);
  text-align: center;
}

.sampleManifest {
  text-transform: capitalize;
  padding: 2px 7px;
  background-color: rgba(9, 43, 74, 0.09);
  font-size: 12px;
  font-weight: 500;
}

/* Third Party modal */
.notificationBellWrapper>.notificationBell {
  margin-top: -6px;
}

@media screen and (max-width: 1440px) and (min-width: 1201px) and (max-width: 476px) {
  .docBtn :global .MuiButton-root {
    min-width: 100%;
  }

  .docBtn :global .MuiButton-root:first-child {
    margin-bottom: 12px;
  }
}

@media screen and (min-width: 1201px) {

  .listDocBody {
    min-height: calc(100vh - 445px);
  }

  .viewDocBody {
    min-height: calc(100vh - 240px);
  }

  .verifyDocBody {
    min-height: calc(100vh - 310px);
  }
}

@media screen and (max-width: 1200px) {
  .docSecVerify {
    width: calc(100% - 410px);
  }

  .docView {
    width: 100%;
  }
}

@media screen and (max-width: 900px) {

  .docSec {
    width: 100%;
  }

  .docView,
  .docSecVerify {
    width: 100%;
  }

  .incomingDocH100 {
    height: auto;
  }
}


@media screen and (max-width: 476px) {
  .docBtn :global .MuiButton-root {
    min-width: 100%;
  }

  .docBtn :global .MuiButton-root:first-child {
    margin-bottom: 12px;
  }
}