.fltReportTableOuter, .logReportTableOuter {
  height: calc(var(--contentBox) - 280px);
}

/* filter */
@media screen and (min-width: 1500px) {
  .headerWraper {
    position: absolute;
    left: 0;
    right: 0;
    top: 50px;
    margin: auto;
    /* width: clamp(200px, 30%, 350px); */
    min-width: 170px;
    max-width: calc(100% - 400px - 310px - 48px - 30px);
  }

  .filterCard {
    padding: 0;
  }

  .filterTitle {
    display: none;
  }

  .tabWrapper>.billingFilterContainer {
    padding-top: 0px;
  }
}

@media screen and (max-width: 1499px) {

  .headerWraper {
    position: static;
    background-color: var(--greybkg);
    padding: 0px;
    border-radius: var(--headerbr);
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    margin-bottom: var(--splayout);
  }
}