/* FLIGHTS */
.inlineEl {
  background-color: var(--primaryblue);
  color: white;
  padding: 4px;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  font-size: 10px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: normal;
}

.nilCheckbox {
  position: absolute;
  left: 18px;
  top: 50%;
  transform: translateY(-50%);
}

.whiteCheckbox {
  color: #dbdadb;
}

.whiteCheckboxLabel {
  font-weight: bold;
}

/* Manifest Summary */
.title {
  font-size: 0.75rem;
  text-transform: uppercase;
  background-color: var(--primaryblue);
  font-weight: bold;
  color: lightgrey;
  font-weight: 800;
  text-align: center;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding: 2px 4px;
  width: 100%;
}

.fields {
  display: block;
  width: 100%;
  border: none;
  background-color: #e0e0e0;
  border-radius: var(--ip-br);
  -webkit-text-fill-color: var(--primaryblue);
  padding: 6px 14px;
  font-size: var(--input-fs);
  line-height: normal;
  min-height: 29.5px;
}

.data {
  font-size: 0.82rem;
  color: var(--primaryblue);
  font-weight: bold;
  background-color: white;
  text-align: center;
  padding: 4px 8px;
  flex: 1;
  width: 100%;
  min-height: 44px;
  max-height: 44px;
  margin-bottom: auto;
}

.summaryHead {
  color: var(--primaryblue);
  font-size: 0.875rem;
  font-weight: bold;
  padding-bottom: 4px;
  border-bottom: 2px solid var(--primaryblue);
  margin-bottom: 14px;
  text-transform: uppercase;
}

.summaryBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dataAlign {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.progressWrapper {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.dataInner {
  max-width: 12ch;
  overflow: hidden;
  text-overflow: ellipsis;
}

.manifestCard,
.manifestCheckbox {
  /* Do not delete */
}

.manifestUpload {
  color: #999;
  font-size: 9px;
  font-weight: 800;
}

.flightTabs {
  padding: 2px 12px;
  justify-content: center;
  min-height: 45px;
}
.fightTabHide {
  display: none;
}

/* Download Modal */
.downloadIcon {
  font-size: 3.5rem;
  color: var(--secondarygreen);
  border: 1px solid var(--secondarygreen);
  margin: auto;
  display: block;
  margin-bottom: 30px;
  border-radius: 50px;
  padding: 6px;
}

.downloadWrapper {
  padding: 50px;
  margin: 10px 0;
  margin-top: 0;
  background-color: #f4f4f4;
  border-radius: 4px;
}

.downloadBtnWrapper {
  margin-top: 30px;
}

.downloadBtnWrapper button {
  width: 100%;
}

.noFlight{
 height: 120px;
 margin-bottom: 24px;
}

.sectionSpacing {
  margin-top: 2rem;
}

.flightIcon {
  transform: rotate(90deg);
  font-size: 18px;
}

.flightInfo {
  border-radius: 20px;
  min-width: 122px;
}

.flightModal{
  padding: 30px;
  padding-top: 30px !important;
}

.modalButton {
  min-width: 160px;
}

.actionButtonContainer {
  padding-top: 30px;
}