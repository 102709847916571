.collapsibleTable {
  height: 300px;
  /* height: calc(var(--contentBox) - 300px); */
}

.accordionFlight {
  background-color: transparent;
  box-shadow: none;
  padding: 0;
}

.accordionFlight.accordionFlightExpanded {
  margin-top: 0;
}

.accordionSummaryFlight {
  flex-direction: row-reverse;
}

.accordionSummaryFlight,
.accordionSummaryFlight.accordionFlightExpanded,
.accordionDetailsFlight {
  background-color: var(--greybkg);
  border-radius: var(--tbbr);
  font-size: 0.78rem;
  font-weight: 590;
  min-height: 36px;
}

.pending {
  color: var(--redText);
}

.pendingSvg {
  margin-left: -25px;
  margin-right: 5px;
}

.adhocFlight {
  font-size: 10px;
  font-weight: 700;
  color: #eee;
  border-radius: 50%;
  border: 1px solid #eee;
  line-height: normal;
  min-width: 18px;
  height: 18px;
  padding: 2px;
  position: absolute;
  left: -29px;
  top: 50%;
  transform: translateY(-50%);
}

.flightStatus {
  font-weight: bolder;
  position: absolute;
  left: 12px;
  border-radius: 50%;
  border: 1px solid var(--redText);
  min-width: 18px;
  height: 18px;
  font-size: 10px;
  color: var(--redText);
}

.flightLabelWrapper {
  width: 100%;
  text-align: left;
}


.flightLabelWrapper>p {
  margin: 0;
  line-height: normal;
}

.selectedFlight {
  background-color: white;
  outline: 3px solid var(--secondarygreen);
}

.accordionContentFlight {
  flex-direction: column;
}

.accordionSummaryFlight,
.accordionDetailsFlight {
  margin-bottom: 16px;
}

.accordionContentFlight,
.accordionContentFlight.accordionFlightExpanded {
  margin: 8px 0;
  min-width: 150px;
  flex-grow: 0;
  margin-left: auto;
}

.accordionDetailsFlight {
  padding: 4px 30px 4px 14px;
  margin-left: 20px;
  text-align: center;
  font-size: 0.8rem;
}

.accordionDetailsFlight {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fltDetails {
  padding-left: 30px;
}

.fltDetailsInner {
  max-height: 450px;
  /* clac content */
  overflow: scroll;
  padding-right: 20px;
  margin-right: -20px;
  scrollbar-width: thin;
  scrollbar-color: #9f9f9f var(--primaryblue);
}

.accordionDetailsFlightMain.fltDetailsInner {
  padding-top: 4px;
}

.accordionDetailsFlightMain {
  padding-top: 0;
  padding-bottom: 0;
}

.accordionDetailsFlightMain:not(.accordionDetailsFlightMain.fltDetailsInner) {
  padding-right: 0;
}

.flightInfo {
  width: 310px;
}

.flightsLoadInfo {
  width: auto;
  min-width: 200px;
}

.fltPaper {
  padding: 14px 20px;
  min-height: 600px;
  height: 100%;
  /* height: 600px; */
  /* clac content */
}

.reportIcon {
  position: absolute;
  right: 12px;
}

/* @media screen and (min-width: 1500px) {
  .headerWraper {
   max-width: calc(100% - 400px - 310px - 48px - 30px);
  }
  .filterCard {
    padding: 0;
  }
  .filterTitle {
    display: none;
  }
}

@media screen and (max-width: 1499px) {

  .headerWraper {
    position: static;
    background-color: var(--greybkg);
    padding: 0px;
    border-radius: var(--headerbr);
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
} */

.downloadInvoice {
  margin: 10px;
}

.downloadInvoice button {
  width: 100%;
  margin-bottom: 10px;
}

.discType {
  background-color: #D021391A;
  color: var(--redText);
  border-radius: 20px;
  padding: 2px 10px;
  font-size: 10px;
  font-weight: 700;
}

/* iframe header {
  display: none;
} */
/* ========= STATISTICS ========== */
.cdtableOuter {
  height: calc(var(--contentBox) - 190px);
}

.dscptableOuter {
  height: calc(var(--contentBox) - 300px);
}

.statTableOuter {
  height: calc(var(--contentBox) - 220px);
}

.summaryCard {
  background-color: #f9f9f9;
  border-radius: var(--tbbr);
  font-size: 0.74rem;
  font-weight: 590;
  min-height: 36px;
  height: 100%;
  padding: 14px;
  display: flex;
  flex-direction: column;
}

.summaryHead {
  border-top: 2px dotted var(--secondarygreen);
}

/* ========= ONEOFF ========== */
.oneOffTableOuter {
  height: 280px;
}

.oneOffBtn {
  text-transform: capitalize;
  padding: 2px 7px;
  background-color: rgba(9, 43, 74, 0.09);
  font-size: 12px;
  font-weight: 500;
}

/* ========= STATISTICS ========== */
/* 
.fltTableOuter {
  height: calc(var(--contentBox) - 108px);
}

tr td[aria-label="Collapse"] {
  color: red;
  font-weight: bold;
  background-color: #cdcbff;

}

td:has(> [aria-label="Collapse"]) svg {
  color: var(--primaryblue);

}

td:has(> [aria-label="Collapse"]),
td:has(> [aria-label="Collapse"])+td,
td:has(> [aria-label="Collapse"])~td {
  color: var(--primaryblue);
  font-weight: bold;
  font-weight: 700;
  background-color: #ddd;
}

td:has(> [aria-label="Collapse"]) > span {
  background-color: #ddd;
}

td:has(> [aria-label="Expand"]) > span {
  background-color: white;
}

tr:hover td:has(> [aria-label="Collapse"]) > span {
  background-color: transparent;
}

tr:hover td:has(> [aria-label="Expand"]) > span {
  background-color: transparent;
}

tr:hover td [aria-label="Collapse"]:before, tr:hover td [aria-label="Expand"]:before {
  content: '';
  position: absolute;
  background-color: transparent;
}

td:has(> [aria-label="Collapse"] > [disabled=""]),
td:has(> [aria-label="Collapse"] > [disabled=""])+td,
td:has(> [aria-label="Collapse"] > [disabled=""])~td {
  background-color: white;
  font-weight: normal;
}

td [aria-label="Collapse"], td [aria-label="Expand"] {
  border-radius: var(--tbbr);
  border-radius: var(--tbbr) 0 0 var(--tbbr);
  position: relative;
}

td:has(> [aria-label="Collapse"]) > span:has(> [disabled=""]) {
  background-color: white;
}

td [aria-label="Collapse"]:before, td [aria-label="Expand"]:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 10px;
  height: 100%;
  background-color: var(--greybkg);
  z-index: -1;
}

.discType {
  background-color: #D021391A;
  color: var(--redText);
  border-radius: 20px;
  padding: 2px 10px;
  font-size: 10px;
  font-weight: 700;
}

.warningIcon {
  position: absolute;
  left: -16px;
}
*/
.regBtn {
  min-height: 32px;
}

.statusPopover {
  min-width: 280px;
}

.statusPopoverHeader {
  padding: 0.5rem 1.2rem;
}

.statusIndicator {
  font-size: 11px;
  font-weight: bold;
  margin-top: 0;
  padding: 1px 12px;
  border-radius: 10px;
  background-color: #ececec;
  color: var(--primaryblue);
  min-width: 75px;
  text-align: center;
}

.statusDesc {
  font-size: 13px;
  font-weight: initial;
  margin-bottom: 10px;
  margin-top: 0;
  display: flex;
  justify-content: space-between;
}

.statusDesc:last-child {
  margin-bottom: 0;
}

.collapsibleTableContainer>.collapsibleTable>.collapsibleTableBody>.collapsibleTableRow>.collapsibleTableCell {
  background-color: white;
  height: 43px;
}

.collapsedTable {
  border-collapse: initial;
  background-color: transparent;
}

.collapsedTable>.collapsedTableHead>.collapsedTableRow>.collapsedTableCell {
  background-color: #092b4a;
  color: #fff;
  font-size: var(--tblheadfs);
  white-space: nowrap;
  padding: 4px 8px;
  font-weight: 600;
  line-height: normal;
  position: sticky;
  z-index: 10;
  height: 32px;
  margin-bottom: 20px;
}

.collapsedTable>.collapsedTableBody>.collapsedTableRow {
  border-radius: var(--tbbr);
}

.collapsedTable>.collapsedTableBody>.collapsedTableRow:first-of-type>.collapsedTableCell:first-of-type {
  border-top-left-radius: inherit;
}

.collapsedTable>.collapsedTableBody>.collapsedTableRow:first-of-type>.collapsedTableCell:last-of-type {
  border-top-right-radius: inherit;
}

.collapsedTable>.collapsedTableBody>.collapsedTableRow:last-of-type>.collapsedTableCell:first-of-type {
  border-bottom-left-radius: inherit;
}

.collapsedTable>.collapsedTableBody>.collapsedTableRow:last-of-type>.collapsedTableCell:last-of-type {
  border-bottom-right-radius: inherit;
}

.collapsedTable>.collapsedTableBody>.collapsedTableRow>.collapsedTableCell {
  background-color: white;
  padding: 4px 8px;
  font-size: var(--tblbodyfs);
  height: 30px;
  border-bottom: none;
}


.collapsibleTableContainer>.collapsibleTable {
  width: 100%;
  border-collapse: unset;
  border-spacing: 0;
  margin-top: 0;
}

.spRow {
  height: 12px;
}

.spRow>* {
  border-bottom: unset;
}

.collapsibleBox {
  width: calc(100% - 50px);
  margin: 12px 0;
  margin-left: auto;
}

.regBtn {
  min-width: 180px;
  min-height: 40px;
}

@media screen and (min-width: 1580px) {
  .infofilter {
    position: absolute;
    top: -23px;
    left: 24px;
    right: 250px;
  }
}

@media screen and (max-width: 991px) {
  .flightInfo {
    width: 100%;
  }

  .accordionContentFlight,
  .accordionContentFlight.accordionFlightExpanded {
    margin: auto;
  }

  .accordionDetailsFlight,
  .accordionSummaryFlight {
    width: 30%;
  }

  .accordionDetailsFlightMain {
    display: flex;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 768px) {

  .accordionDetailsFlight,
  .accordionSummaryFlight {
    width: 44%;
  }
}

@media screen and (max-width: 600px) {

  .accordionDetailsFlight,
  .accordionSummaryFlight {
    width: 100%;
  }
}