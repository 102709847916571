.historyIcon {
  width: auto;
  min-width: auto;
  line-height: unset;
  padding: 0;
  font-weight: 800;
  font-size: 10px;
  width: 14px;
  height: 14px;
}

.smallIcon img {
  width: auto;
  height: 14px;
}

.awbTable .awbRow .awbCell {
  padding: 8px 8px;
  align-items: start;
}

.amount {
  min-width: 80px;
}

.delIcon img {
  height: 16px;
  width: auto;
  margin-bottom: 6px;
}

.updateButton {
  width: clamp(100px, calc(50% - 4px), 255px);
  /* width: calc(50% - 4px); */
}

.radioGroupCard {
  padding-top: 12px;
  padding-bottom: 12px;
}

.titleAddIcon {
  width: 20px;
  height: 20px;
  font-size: 0;
}

.titleEditIcon {
  width: 18px;
  height: 20px;
  font-size: 0;
}

.titleAddIcon img,
.titleEditIcon img {
  width: 100%;
  height: 100%;
}

.horizontalLine {
  flex-grow: 2;
  background-color: var(--primaryblue);
  height: 2px;
  margin-left: 2%;
  margin-top: 10px;
}

.sliderRightArrow {
  position: absolute;
}

.sliderLeftArrow,
.sliderRightArrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
  z-index: 1;
  font-size: 3rem;
}

.sliderLeftArrow {
  left: -14px;
}

.sliderRightArrow {
  right: -14px;
}

.docTypeSlider {
  width: 100%;
  background-color: var(--primaryblue);
  color: white;
  padding-right: 30px;
  padding-left: 30px;
}

.docTypeRadioGroup .sliderLabel {
  color: white;
  min-width: 200px;
}

/* SEND NOTIFICATION */
.chipLabel {
  color: var(--primaryblue);
  font-weight: 500;
}

.pdfWrapper {
  background-color: white;
  border-radius: 10px;
  margin-right: 10px;
  margin-left: 10px;
  text-align: center;
  padding: 6px;
  padding-top: 12px;
  max-width: 120px;
}

.pdfWrapper img {
  margin: auto;
}

/* HISTORY */
.historyStatusIcons img {
  height: 14px;
  width: auto;
}

.historyStatusIcons svg {
  height: 18px;
  width: auto;
}

.historyStatusActionIcons img,
.historyStatusActionIcons svg {
  height: 16px;
  width: auto;
}

.docTypeRadio {
  background-color: white;
  color: green;
  padding: 0;
  margin-right: 10px;
  margin-left: 15px;
}

.notfyHistoryModal {
  width: 450px;
}

/* Part Arrival Info */
.partArrivalModal {
  background-color: var(--primaryblue);
  color: white;
  width: 400px;
}

.popoverDarkTitleDivider {
  border-color: var(--secondarygreen);
}

.whiteCheckbox .whiteCheckboxLabel {
  font-weight: 500;
}

.pdfContainer {
  width: 100%;
  height: 600px;
  /* Adjust height */
  overflow: auto;
  /* Allow scrolling */
  cursor: grab;
  user-select: none;
  /* Prevent text selection */
  /* Optional: border for visibility */
}

.pdfContainer:active {
  cursor: grabbing;
}

/* .pdfPage {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
} */

.pdfContainer canvas {
  margin: auto;
}

.attachmentPage canvas {
  margin: auto;
}

/* collapsible */


.rowSpanTableOuter {
  height: calc(var(--contentBox) - 112px);
}

.contractLogTableOuter {
  height: calc(var(--contentBox) - 185px);
}

.contractLogTable .contractLogTableRow .contractLogTableCell {
  margin-bottom: auto;
}

.headerIcon img {
  width: 14px;
}

.attImgContainer {
  width: 175px;
}

.attachmentPopover {
  margin-left: -10px;
}

.reportTable {
  max-height: calc(100vh - 430px);
  overflow: auto;
}

.nestedCells :global .MuiTable-root>.MuiTableBody-root>.MuiTableRow-root.las>.MuiTableCell-root {
  padding-top: 0;
}


.brNone {
  border-radius: 0;
}

.nestedCells :global .MuiTable-root>.MuiTableBody-root>.MuiTableRow-root.lastRow .MuiTableCell-root:last-of-type {
  border-radius: 0;
}

.firstRow td {
  height: 36px;
}

.firstRow :global .MuiTableCell-root,
.lastRow :global .MuiTableCell-root {
  border-bottom: none;
}



.rowSpanTable :global .MuiTable-root>.MuiTableBody-root>.MuiTableRow-root>.MuiTableCell-root:last-of-type {
  border-radius: 0;
}

.attachIcon {
  margin-top: -10px;
  margin-left: -10px;
}


.firstRow td {
  padding: 0;
}

/* contract line item */
.labelInline {
  min-width: 120px;
  max-width: 132px;
}

.labelInlineRhs {
  min-width: 130px;
  max-width: 130px;
}

.popoverWrapper {
  padding: 24px;
  max-width: 580px;
  max-height: 610px;
  overflow: auto;
}

.desc:not(:last-child) {
  border-bottom: 1px solid #dbdbdb;
  padding-bottom: 6px;
  margin-bottom: 6px;
}

.resendOtp {
  padding: 2px 10px;
  text-transform: capitalize;
  font-size: 12px;
}

.nestedHeadCell:not(:empty) {
  background-color: #092b4a;
  color: #fff;
  font-weight: 600;
}

.collapsibleTableRow,
.collapsedTableRow {
  border-radius: var(--tbbr);
  vertical-align: top;
  height: 40px;
}

.collapsedTable {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 20px;
  margin: -20px 0;
}

.collapsibleTable>.collapsibleTableHead>.collapsibleHeadRow>.collapsibleTableCell {
  position: sticky;
  top: 0;
  z-index: 10;
}

.collapsibleTableContainer>.collapsibleTable>.collapsibleTableBody>.collapsibleTableRow>.collapsibleTableCell:first-of-type {
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
}

.collapsibleTableContainer>.collapsibleTable>.collapsibleTableBody>.collapsibleTableRow>.collapsibleTableCell:last-of-type {
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
}

.collapsibleTableContainer>.collapsibleTable>.collapsibleTableBody>.collapsibleTableRow>.collapsibleTableCell {
  background-color: white;
}

.collapsedTable>.collapsedTableHead>.collapsedTableRow>.collapsedTableCell {
  background-color: #092b4a;
  color: #fff;
  font-size: var(--tblheadfs);
  white-space: nowrap;
  padding: 4px 8px;
  font-weight: 600;
  line-height: normal;
  /* position: sticky;
  z-index: 10;
  top: 108px; */
  height: 32px;
}

.collapsedTable>.collapsedTableHead>.collapsedTableRow>.emptyCollapsedTableCell {
  border-bottom: none;
}

.collapsibleTableContainer>.collapsibleTable>.collapsibleTableHead .headerBlock {
  background-color: var(--greybkg);
  position: sticky;
  top: 32px;
  z-index: 10;
  height: 16px;
}

.collapsibleTable>.collapsedTable>.collapsedTableBody>.firstRow>.collapsedTableCell {
  background-color: white;
}

.collapsedTable>.collapsedTableBody>.collapsedTableRow>.collapsedTableCell:first-of-type {
  border-top-left-radius: var(--tbbr);
  border-bottom-left-radius: var(--tbbr);
  border-left: 1px solid #fff;
}

.collapsedTable>.collapsedTableBody>.collapsedTableRow>.collapsedTableCell:last-of-type {
  border-top-right-radius: var(--tbbr);
  border-bottom-right-radius: var(--tbbr);
  border-right: 1px solid #fff;
}

.collapsedTable>.collapsedTableBody>.collapsedTableRow>.collapsedTableCell {
  background-color: rgba(2, 167, 240, 0.075);
  border: 1px solid #fff;
  border-right: none;
  border-left: none;
}

.collapsibleTableContainer>.collapsibleTable>.collapsibleTableBody>.collapsibleTableRow>.collapsibleTableCell,
.collapsedTable>.collapsedTableBody>.collapsedTableRow>.collapsedTableCell {
  padding: 8px 8px;
  font-size: var(--tblbodyfs);
  position: relative;
}

.collapsibleTableContainer>.collapsibleTable>.collapsibleTableBody>.collapsibleTableRow .collapsibleTableCellMain {
  border-bottom: none;
  padding-top: 1rem;
}

.collapsibleTableContainer {
  height: calc(var(--contentBox) - 0px);
}

.collapsibleTableContainer>.collapsibleTable>.collapsibleTableBody>.collapsibleTableRow>.collapsibleTableCell:after,
.collapsedTable>.collapsedTableBody>.collapsedTableRow>.collapsedTableCell:after {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  top: 10px;
  width: 1px;
  height: 30%;
  background-color: #eeeeeec4;
}

@media screen and (max-width: 1200px) {
  .collapsibleTableContainer {
    height: calc(var(--contentBox) - 200px);
  }
}

@media screen and (max-width: 599px) {
  .minIp {
    width: 100%;
  }
}


/* Document Viewer */

.placeholderText {
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 40px);
  text-align: center;
}

.docIcon,
.docIcon button {
  height: 20px;
}

.docIcon img {
  width: auto;
  height: 100%;
}

.pdf-slider-container {
  min-width: 500px;
  min-height: 410px;
  text-align: center;
}

.attachmentPage canvas {
  margin: auto;
}

.pagination {
  margin: 0 auto;
}

.tsleftArrow,
.tsrightArrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
  z-index: 1;
}

.tsleftArrow {
  left: -14px;
}

.tsrightArrow {
  right: -14px;
}

.pdfParentContainer {
  --containerHeight: 660px;
  height: var(--containerHeight);
  overflow: hidden;
}

.pdfContainer {
  height: calc(var(--containerHeight) + 16px);
  width: calc(100% + 16px) !important;
  overflow: auto;
}

.pdfContainer1 {
  width: 100%;
  height: 600px;
  cursor: grab;
  user-select: none;
  overflow-y: scroll;
  overflow-x: scroll;
  padding-right: 17px;
  box-sizing: content-box;
  padding-bottom: 17px;
}

.pdfContainer:active {
  cursor: grabbing;
}

.pdfContainer canvas {
  margin: auto;
}

.attachmentPage canvas {
  margin: auto;
}

.docViewerCard {
  margin: auto;
}

.addButton {
  padding: 4px 12px;
}