.accordionFlight {
  background-color: transparent;
  box-shadow: none;
  padding: 0;
}

.accordionFlight.accordionFlightExpanded {
  margin-top: 0;
}

.accordionSummaryFlight {
  flex-direction: row-reverse;
}

.accordionSummaryFlight,
.accordionSummaryFlight.accordionFlightExpanded,
.accordionDetailsFlight {
  background-color: var(--greybkg);
  border-radius: var(--tbbr);
  font-size: 0.74rem;
  font-weight: 590;
  min-height: 36px;
}

.selectedFlight {
  background-color: white;
  border: 3px solid var(--secondarygreen);
}

.accordionSummaryFlight,
.accordionDetailsFlight {
  margin-bottom: 16px;
}

.accordionContentFlight,
.accordionContentFlight.accordionFlightExpanded {
  margin: 8px 0;
  min-width: 150px;
  flex-grow: 0;
  margin-left: auto;
}

.accordionDetailsFlight {
  padding: 0;
  margin-left: 40px;
  text-align: center;
}

.accordionDetailsFlight {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fltDetails {
  padding-left: 40px;
}

.fltDetailsInner {
  max-height: 380px;
  /* clac content */
  overflow: scroll;
  padding-right: 20px;
  margin-right: -20px;
  scrollbar-width: thin;
  scrollbar-color: #9f9f9f var(--primaryblue);
}

.accordionDetailsFlightMain {
  padding-top: 0;
  padding-bottom: 0;
}

.accordionDetailsFlightMain:not(.accordionDetailsFlightMain.fltDetailsInner) {
  padding-right: 0;
}

.flightInfo {
  width: 340px;
}

.flightsLoadInfo {
  width: auto;
  min-width: 200px;
}

.fltPaper {
  padding: 14px 20px;
  height: 600px;
  /* clac content */
}

.reportIcon {
  position: absolute;
  right: 12px;
}

.minWidthIp {
  min-width: 216px;
}

/* @media screen and (min-width: 1500px) {
  .headerWraper {
   max-width: calc(100% - 400px - 310px - 48px - 30px);
  }
  .filterCard {
    padding: 0;
  }
  .filterTitle {
    display: none;
  }
}

@media screen and (max-width: 1499px) {

  .headerWraper {
    position: static;
    background-color: var(--greybkg);
    padding: 0px;
    border-radius: var(--headerbr);
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
} */

/* .tabs {
  margin-top: 70px;
} */

/* ========= STATISTICS ========== */
.cdtableOuter {
  height: calc(var(--contentBox) - 300px);
}

/* ========= STATISTICS ========== */
/* 
.fltTableOuter {
  height: calc(var(--contentBox) - 108px);
}

tr td[aria-label="Collapse"] {
  color: red;
  font-weight: bold;
  background-color: #cdcbff;

}

td:has(> [aria-label="Collapse"]) svg {
  color: var(--primaryblue);

}

td:has(> [aria-label="Collapse"]),
td:has(> [aria-label="Collapse"])+td,
td:has(> [aria-label="Collapse"])~td {
  color: var(--primaryblue);
  font-weight: bold;
  font-weight: 700;
  background-color: #ddd;
}

td:has(> [aria-label="Collapse"]) > span {
  background-color: #ddd;
}

td:has(> [aria-label="Expand"]) > span {
  background-color: white;
}

tr:hover td:has(> [aria-label="Collapse"]) > span {
  background-color: transparent;
}

tr:hover td:has(> [aria-label="Expand"]) > span {
  background-color: transparent;
}

tr:hover td [aria-label="Collapse"]:before, tr:hover td [aria-label="Expand"]:before {
  content: '';
  position: absolute;
  background-color: transparent;
}

td:has(> [aria-label="Collapse"] > [disabled=""]),
td:has(> [aria-label="Collapse"] > [disabled=""])+td,
td:has(> [aria-label="Collapse"] > [disabled=""])~td {
  background-color: white;
  font-weight: normal;
}

td [aria-label="Collapse"], td [aria-label="Expand"] {
  border-radius: var(--tbbr);
  border-radius: var(--tbbr) 0 0 var(--tbbr);
  position: relative;
}

td:has(> [aria-label="Collapse"]) > span:has(> [disabled=""]) {
  background-color: white;
}

td [aria-label="Collapse"]:before, td [aria-label="Expand"]:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 10px;
  height: 100%;
  background-color: var(--greybkg);
  z-index: -1;
}

.discType {
  background-color: #D021391A;
  color: var(--redText);
  border-radius: 20px;
  padding: 2px 10px;
  font-size: 10px;
  font-weight: 700;
}

.warningIcon {
  position: absolute;
  left: -16px;
}
*/

@media screen and (min-width: 1500px) {
  .headerWraper {
    position: absolute;
    left: 0;
    right: 0;
    top: 50px;
    margin: auto;
    /* width: clamp(200px, 30%, 350px); */
    min-width: 170px;
    max-width: calc(100% - 400px - 310px - 20px);
  }

  .filterCard {
    padding: 0;
  }

  .filterTitle {
    display: none;
  }

.billingContainer >.billingFilterContainer {
    padding-top: 0px;
  }
}

@media screen and (max-width: 1499px) {

  .headerWraper {
    position: static;
    background-color: var(--greybkg);
    padding: 0px;
    border-radius: var(--headerbr);
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    /* margin: var(--splayout) 0; */
  }
}