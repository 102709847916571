.consigneeTableOuter {
  height: calc(var(--contentBox) - 96px);
}

.addServicesTableOuter {
  height: calc(var(--contentBox) - 96px);
}

.operationCard {
  background-color: white;
  margin-bottom: 10px;
  padding: 12px 18px;
  border-radius: var(--ip-br);
}

.smallIcon img {
  width: auto;
}

.operationsTextfield .operationsIpRoot .operationsIp {
  padding: 6px 8px;
}

.operationsIpRoot .operationsIp {
  padding-left: 2px;
  padding-right: 2px;
}

.operationsIpRoot .operationsIpSelect {
  padding-left: 2px;
  padding-right: 2px;
}

.date {
  background-color: var(--secondarygreen);
  color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.datePicker {
  border-radius: 12px;
}

.addBtn {
  padding: 5px 12px;
  margin-top: auto;
  margin-left: auto;
}

.labelCheckbox {
  display: flex;
  align-items: flex-start;
}

.delIcon img {
  height: 16px;
  width: auto;
  margin-bottom: 6px;
}

.removeHoliday {
  position: absolute;
  top: -3px;
  right: -4px;
  padding: 0;
  color: white;
  background-color: #092b4a70;
}

.arrowIcon {
  width: 18px;
  height: 18px;
  padding: 2px;
  border-radius: 50%;
  border: 1px solid var(--primaryblue);
}