.manifestDetails {
  background-color: white;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 10px;
  width: 100%;
}

.manifestTable th {
  padding: 0px;
  border-bottom: none;
}

.manifestTable td {
  padding: 4px;
  font-size: 0.8rem;
  border-bottom: none;
  white-space: nowrap;
  color: var(--primaryblue);
}

.smSelect {
  padding-top: 2px;
  padding-bottom: 2px;
  width: 100px;
}

/* discrepancy */

.discTableOuter {
  height: clamp(150px, calc(var(--contentBox) - 580px), 90vh);
}

.discpCard {
  overflow: visible;
}

.discExcelIcon img {
  height: 26px;
}