.attIcon {
  transform: rotate(-25deg);
  height: 20px;
}

.likesNumber {
  font-weight: 900; 
  background-color: #092b4a1c ;
  color: var(--primaryblue);
  min-width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.notesAdded {
  background-color: white;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 10px;
  width: 100%;
  border: 1px solid #e6e6e6;
}

.likeWrapper {
  margin-left: -20px;
}

.attWrapper {
  max-width: 200px;
  margin-top: 0.3rem;
  margin-left: 3.6rem;
}

.attWrapper img.attImg {
  width: 100%;
  margin-top: 10px;
}

.cameraIcon img {
  height: 20px;
  margin-left: 8px;
}

.smNoteIcons {
  width: 15px;
  padding: 0 !important;
}

.addNotes img {
  width: 20px;
}

.smNoteIcons img {
  width: 100%;
  /* margin-bottom: 10px; */
}

.replyWrapper {
  max-width: 90%;
}

.attInputIcon {
  height: 18px;
  margin-top: auto;
  display: block;
  color: var(--primaryblue);
}

.ml40 {
  margin-left: 40px;
}

.viewAt {
  position: relative;
}

.viewAtt:hover:before {
  content: 'View attachment';
  position: absolute;
  top: 10px;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 700;
  text-transform: capitalize;
  font-size: 12px;
  background-color: rgba(0, 0, 0, 0.67);
  padding: 10px;
  border-radius: 7px;
}

.noteWrapper {
  width: 90%;
  margin: auto;
}

.pdfWrapper {
  background-color: white;
  border-radius: 10px;
  padding: 0.8rem;
  width: max-content;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pdfWrapper {
  width: 90px;
}

.ExcelImg {
  width: 100%;
  padding: 4px 2px;
  height: 100%;
}