
.rowSpanTableOuter {
  height: calc(var(--contentBox) - 112px);
}

.contractLogTableOuter {
  height: calc(var(--contentBox) - 185px);
}

.contractLogTable .contractLogTableRow .contractLogTableCell {
  margin-bottom: auto;
}

.headerIcon img {
  width: 14px;
}

.attImgContainer {
  width: 175px;
}

.attachmentPopover {
  margin-left: -10px;
}

.reportTable {
  max-height: calc(100vh - 430px);
  overflow: auto;
}

.nestedCells :global .MuiTable-root>.MuiTableBody-root>.MuiTableRow-root.las>.MuiTableCell-root {
  padding-top: 0;
}


.brNone {
  border-radius: 0;
}

.nestedCells :global .MuiTable-root>.MuiTableBody-root>.MuiTableRow-root.lastRow .MuiTableCell-root:last-of-type {
  border-radius: 0;
}

.firstRow td {
  height: 36px;
}

.firstRow :global .MuiTableCell-root,
.lastRow :global .MuiTableCell-root {
  border-bottom: none;
}


.lastRowAction {
  width: 75px;
  padding-right: 16px !important;
}

.smallIcon img {
  width: auto;
}

.rowSpanTable {
  border-spacing: 0;
}

.rowSpanTable :global .MuiTable-root {
  border-spacing: 0;
  margin-top: 0;
}

.rowSpanTable :global .MuiTable-root>.MuiTableBody-root>.MuiTableRow-root>.MuiTableCell-root:last-of-type {
  border-radius: 0;
}

.attachIcon {
  margin-top: -10px;
  margin-left: -10px;
}


.firstRow td {
  padding: 0;
}

/* contract line item */
.labelInline {
  min-width: 120px;
  max-width: 132px;
}
.labelInlineRhs {
  min-width: 130px;
  max-width: 130px;
}

.popoverWrapper {
  padding: 24px;
  max-width: 580px;
  max-height: 610px;
  overflow: auto;
}

.desc:not(:last-child) {
  border-bottom: 1px solid #dbdbdb;
  padding-bottom: 6px;
  margin-bottom: 6px;
}

.resendOtp {
  padding: 2px 10px;
  text-transform: capitalize;
  font-size: 12px;
}

.nestedHeadCell:not(:empty) {
  background-color: #092b4a;
  color: #fff;
  font-weight: 600;
}

.collapsibleTableRow,
.rowEnd,
.rowStart {
  border-radius: var(--tbbr);
}
.collapsibleTable>.collapsibleTableHead>.collapsibleHeadRow>.collapsibleTableCell {
  position: sticky;
  top: 0;
  z-index: 10;  
}

.collapsibleTableContainer>.collapsibleTable>.collapsibleTableBody>.collapsibleTableRow.collapsibleTableRowSticky {
  position: relative;
  height: 36px;
  position: sticky;
  top: 48px;
  z-index: 3;
}

.collapsibleTableContainer>.collapsibleTable>.collapsibleTableBody>.collapsibleTableRow.collapsibleTableRowSticky:after {
  content: '';
  position: absolute;
  width: 100%;
  bottom: -1rem;
  left: 0px;
  right: 0;
  z-index: 4;
  height: 1rem;
}

.collapsibleTableContainer>.collapsibleTable>.collapsibleTableBody>.collapsibleTableRow>.collapsibleTableCell:first-of-type {
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
}

.collapsibleTableContainer>.collapsibleTable>.collapsibleTableBody>.collapsibleTableRow>.collapsibleTableCell:last-of-type {
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
}

/* COLLIPSIBLE TABLE  */
.collapsibleTableContainer .collapsedTable>.collapsedTableBody>.spacingRowStart + .rowStart>.collapsedTableCell:nth-child(2),
.collapsibleTableContainer .collapsedTable>.collapsedTableBody>.spacingRowStart + .rowEnd>.collapsedTableCell:nth-child(2) {
  border-top-left-radius: inherit;

}
.collapsibleTableContainer .collapsedTable>.collapsedTableBody>.spacingRowStart + .rowStart>.collapsedTableCell:last-of-type,
.collapsibleTableContainer .collapsedTable>.collapsedTableBody>.spacingRowStart + .rowEnd>.collapsedTableCell:last-of-type {
  border-top-right-radius: inherit;
}

.collapsibleTableContainer .collapsedTable>.collapsedTableBody>.rowEnd:has( + .spacingRow)>.collapsedTableCell:nth-child(2),
.collapsibleTableContainer .collapsedTable>.collapsedTableBody>.rowStart:has( + .spacingRow)>.collapsedTableCell:nth-child(2) {
  border-bottom-left-radius: inherit;
}

.collapsibleTableContainer .collapsedTable>.collapsedTableBody>.rowEnd:has( + .spacingRow) >.collapsedTableCell:last-of-type,
.collapsibleTableContainer .collapsedTable>.collapsedTableBody>.rowStart:has( + .spacingRow) >.collapsedTableCell:last-of-type {
  border-bottom-right-radius: inherit;
}

/* ONLY ROW */
.collapsibleTableContainer .collapsedTable>.collapsedTableBody>.spacingRowStart + .rowEnd:has( + .spacingRow)>.collapsedTableCell {
  padding: 8px;
}
.collapsibleTableContainer .collapsedTable>.collapsedTableBody>.spacingRowStart + .rowEnd:has( + .spacingRow)>.collapsedTableCell:nth-child(2) {
  border-top-left-radius: inherit;
}
.collapsibleTableContainer .collapsedTable>.collapsedTableBody>.spacingRowStart + .rowEnd:has( + .spacingRow)>.collapsedTableCell:last-of-type {
  border-top-right-radius: inherit;
}

.rateInput {
  flex: 1 0 90px;
}

.noLimitWrapper {
  margin-right: 0;
  justify-content: flex-start;
}

.collapsibleTableContainer .collapsedTable > .collapsedTableBody > .lastRow > .collapsedTableCell.commentIconCell {
  font-size: 0;
}

.collapsibleTableContainer .collapsedTable > .collapsedTableBody > .lastRow:hover .collapsedTableCell:not(:first-child){
  background-color: #eeeeee;
}

.tieredRate {
  min-width: 50px;
}

.tieredNo {
  min-width: 50px;
  text-align: center; 
}

.tieredLevel {
  min-width: 50px;
  text-align: right; 
}

.collapsibleTableContainer>.collapsibleTable>.collapsibleTableBody>.collapsibleTableRow>.collapsibleTableCell {
  background-color: white;
}

.collapsedTable>.collapsedTableHead>.collapsedTableRow>.collapsedTableCell {
  background-color: #092b4a;
  color: #fff;
  font-size: var(--tblheadfs);
  white-space: nowrap;
  padding: 4px 8px;
  font-weight: 600;
  line-height: normal;
  /* position: sticky;
  z-index: 10;
  top: 108px; */
  height: 32px;
}

.collapsedTable>.collapsedTableHead>.collapsedTableRow>.emptyCollapsedTableCell{
  border-bottom: none;
}

.collapsibleTableContainer>.collapsibleTable>.collapsibleTableHead .headerBlock {
  background-color: var(--greybkg);
  position: sticky;
  top: 32px;
  z-index: 10;
  height: 16px;
}

.collapsibleTable>.collapsedTable>.collapsedTableBody>.firstRow>.collapsedTableCell {
  background-color: white;
  border-top: 1px solid #f2f2f2;
}

.collapsedTable>.collapsedTableBody>.collapsedTableRow>.collapsedTableCell:not(:first-of-type) {
  background-color: white;
  padding: 4px 8px;
  font-size: var(--tblbodyfs);
  height: 30px;
  border-bottom: none;
}

.collapsibleTableContainer>.collapsibleTable {
  width: 100%;
  border-collapse: unset;
  border-spacing: 0;
  margin-top: 0;
}

.collapsibleTableContainer>.collapsibleTable>.collapsibleTableBody>.collapsibleTableRow .collapsibleTableCellMain {
  border-bottom: none;
  padding-top: 1rem;
}
.collapsibleTableContainer {
  height: calc(var(--contentBox) - 104px);
} 


@media screen and (max-width: 1200px) {
  .collapsibleTableContainer {
    height: calc(var(--contentBox) - 200px);
  }
}

@media screen and (max-width: 599px) {
  .minIp {
    width: 100%;
  }
}