body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*,
body {
  box-sizing: border-box;
  font-family: "Nunito", sans-serif;
}

body {
  /* font-size: 14px; */
  padding: var(--splayout);
}

body,
html {
  font-size: 16px;
}

/* ========== font-family =========== */

@font-face {
  font-family: "Nunito";
  src: url(./assets/fonts/Nunito-ExtraLight.ttf);
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: "Nunito";
  src: url(./assets/fonts/Nunito-ExtraLightItalic.ttf);
  font-style: italic;
  font-weight: 200;
}

@font-face {
  font-family: "Nunito";
  src: url(./assets/fonts/Nunito-Light.ttf);
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "Nunito";
  src: url(./assets/fonts/Nunito-LightItalic.ttf);
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: "Nunito";
  src: url(./assets/fonts/Nunito-Regular.ttf);
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Nunito";
  src: url(./assets/fonts/Nunito-Italic.ttf);
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "Nunito";
  src: url(./assets/fonts/Nunito-Black.ttf);
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Nunito";
  src: url(./assets/fonts/Nunito-Medium.ttf);
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Nunito";
  src: url(./assets/fonts/Nunito-MediumItalic.ttf);
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: "Nunito";
  src: url(./assets/fonts/Nunito-SemiBold.ttf);
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "Nunito";
  src: url(./assets/fonts/Nunito-SemiBoldItalic.ttf);
  font-style: italic;
  font-weight: 600;
}

@font-face {
  font-family: "Nunito";
  src: url(./assets/fonts/Nunito-Bold.ttf);
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Nunito";
  src: url(./assets/fonts/Nunito-BoldItalic.ttf);
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: "Nunito";
  src: url(./assets/fonts/Nunito-ExtraBold.ttf);
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: "Nunito";
  src: url(./assets/fonts/Nunito-ExtraBoldItalic.ttf);
  font-style: italic;
  font-weight: 800;
}

@font-face {
  font-family: "Nunito";
  src: url(./assets/fonts/Nunito-Black.ttf);
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: "Nunito";
  src: url(./assets/fonts/Nunito-BlackItalic.ttf);
  font-style: italic;
  font-weight: 900;
}

:root {
  --primaryblue: #092b4a;
  /* same as theme.palette.primary.main */
  --secondaryblue: #00325e;
  --secondarygreen: #59b362;
  /* same as theme.palette.secondary.main */
  --amber: #f9c037;
  --grey: #c9c9c9;
  --splayout: 24px;
  /* same as theme.spacing(6) */
  --tbbr: 8px;
  /* table border radius */
  --disabledfilter: grayscale(1);
  --disabledop: 0.4;
  --xs: 0;
  --sm: 600;
  --md: 900;
  --lg: 1200;
  --xl: 1536;
  --input-fs: 0.82rem;
  --redText: #D02139;
  --ip-br: 4px;
  --tblheadfs: 0.82rem;
  --tblbodyfs: 0.82rem;
  --greybkg: #e8e8e8;
  --gap: 24px;
  --headerheight: 80px;
  --headerbr: 16px;
  --contentBox: 100vh - var(--headerheight) - 24px - 24px - 24px;

  /* status */
  --not_finalized: var(--primaryblue);
  --in_progress: var(--amber);
  --finalized: var(--secondarygreen);
  --approved: var(--secondarygreen);
  --rejected: var(--redText);

}

/* webkit browsers */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #d6d6d6;
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: #555555;
  border-radius: 5px
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
  border: none !important;

}


@media screen and (max-width: 600px) {
  :root {
    --splayout: 12px;
    /* same as theme.spacing(6) */
    --contentBox: 100vh - var(--headerheight) - 24px - 24px - 24px
  }
}