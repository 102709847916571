/* ========= ICONS ======== */
/* ========= INPUT ======== */
/* ========= SELECT DROPDOWN ======== */
/* ========= SNACKBAR ======== */
/* ========= TABS ======== */
/* ========= CUSTOM TABS ======== */
/* ========= CUSTOM RADIO TABS ======== */
/* ========= TABLE(WITH BORDER) ======== */
/* ========= CUSTOM TABLE ======== */
/* ========= LABEL ======== */
/* ========= LINK ======== */

/* ============================== CSS Styles ============================== */
/* ========== content-alignment =========== */
/* ========== font-size =========== */
/* ========== width & height =========== */
/* ========== position =========== */
/* ========== cursor =========== */
/* ========== color =========== */
/* ========== wrap & overflow =========== */
/* ========== padding & margin =========== */

/* ============================== Custom CSS  Styles ============================== */

.department {
  background-color: #e3e3e3;
  color: var(--primaryblue);
  margin-right: 4px;
  padding: 1px 4px;
  font-size: 11px;
  font-weight: bold;
  border-radius: 4px;
  line-height: 2.2;
}
/* ========= ICONS ======== */
.whiteSvg {
  filter: invert(98%) sepia(2%) saturate(0%) hue-rotate(174deg) brightness(100%) contrast(130%);
  opacity: 0.8;
}

.redSvg {
  filter: invert(36%) sepia(74%) saturate(3744%) hue-rotate(339deg) brightness(82%) contrast(103%);
}

.greySvg {
  filter: invert(55%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(92%) contrast(94%);
  opacity: 0.65;
}

.greenSvg {
  filter: invert(62%) sepia(21%) saturate(996%) hue-rotate(75deg) brightness(84%) contrast(90%);
}

.primarySvg {
  filter: invert(16%) sepia(14%) saturate(7498%) hue-rotate(188deg) brightness(99%) contrast(108%);
}

.whiteIcon {
  color: #bbc0c7;
}

.reportIcon {
  /* margin-left: 6px; */
  border-radius: 50%;
  height: 10px;
  width: 10px;
}

.disabledImg {
  filter: var(--disabledfilter);
  opacity: var(--disabledop);
  pointer-events: none;
}

.disabledIconImg img {
  filter: var(--disabledfilter);
  opacity: 0.2;
  pointer-events: none;
}

.tableIcon {
  max-width: 25px;
}

.tableIcon img {
  width: 100%;
}

.addIcon {
  width: 26px;
}

.addIcon img {
  width: 100%;
}

.actionIcons img,
.actionIcons svg {
  height: 14px;
}

.iconMid svg {
  font-size: 20px;
}

.btnIcons {
  width: 14px;
}

.smActionIcons img,
.smActionIcons svg {
  height: 12px;
}

.formClear {
  font-size: 19px;
  font-weight: 700;
  color: var(--redText);
  text-transform: lowercase;
  line-height: 1;
}

.sqIconsContainer {
  margin-left: auto;
}

.sqIcons {
  background-color: var(--primaryblue);
  color: var(--greybkg);
  padding: 4px;
  border-radius: 5px;
  margin-top: auto;
  margin-bottom: 4px;
  font-size: 1.7rem;
  min-width: 28px;
  height: 28px;
}

.sqIconsSecondaryBlue {
  background-color: var(--secondaryblue);
}

.squareIcon {
  background-color: #00325e;
  /* bkg same as add icon */
  border-radius: 6px;
  padding: 2px;
}

.squareIcon img {
  filter: invert(100%) sepia(5%) saturate(60%) hue-rotate(235deg) brightness(111%) contrast(82%);
  min-width: 100%;
}

.fileName {
  overflow: hidden;
  max-width: 20ch;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
}

.attInputIcon {
  height: 18px;
  margin-top: auto;
  display: block;
  color: var(--primaryblue);
}

.noData {
  display: flex;
  align-items: center;
  justify-content: center;
  color: gray;
}

.dotLarge {
  text-align: center;
  border-radius: 50px;
  font-weight: 600;
  width: 12px;
  height: 12px;
  display: inline-block;
}

.pending {
  background-color: var(--amber);
}

.approved {
  background-color: var(--secondarygreen);
}

.rejected {
  background-color: var(--redText);
}

.infoIcon {
  font-size: 9px;
  font-weight: 700;
  color: #092b4a;
  border-radius: 50%;
  border: 1px solid #092b4a82;
  line-height: normal;
  min-width: 16px;
  height: 16px;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f1f1f1;
}

.bell {
  width: auto;
}

.bellAnimation:hover+.iconBtn .bell,
.bell:hover,
.bell:focus {
  animation: bellshake .5s cubic-bezier(.36, .07, .19, .97) both;
  backface-visibility: hidden;
  transform-origin: top right;
}

@keyframes bellshake {
  0% {
    transform: rotate(0);
  }

  15% {
    transform: rotate(5deg);
  }

  30% {
    transform: rotate(-5deg);
  }

  45% {
    transform: rotate(4deg);
  }

  60% {
    transform: rotate(-4deg);
  }

  75% {
    transform: rotate(2deg);
  }

  85% {
    transform: rotate(-2deg);
  }

  92% {
    transform: rotate(1deg);
  }

  100% {
    transform: rotate(0);
  }
}

/* ========= MODAL ======== */
/* .modalCard {
  padding: 20px;
}

.modalCardTitle {
  font-size: 0.9rem;
  padding-bottom: 8px;
}

/* ========= INPUT ======== */
.checkboxWrapper {
  transform: scale(0.8);
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform-origin: left top;
  -moz-transform-origin: left top;
  -o-transform-origin: left top;
}

.whiteCheckbox {
  color: white;
}

.headerFieldContainer {
  position: absolute;
  left: 0;
  right: 0;
  top: 50px;
  margin: auto;
  width: clamp(200px, 30%, 350px);
  min-width: 170px;
  max-width: calc(100% - 310px - 310px - 48px - 30px);
}

.headerFieldContainer>div {
  margin: auto;
}

.headerFieldContainer.flightInfoHeader {
  top: -80px;
}


.ipTimePicker {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: var(--input-fs);
  border-radius: var(--ip-br);
  padding-left: 10px;
  border: none;
  outline: none;
}

.brInput :global .MuiInputBase-root,
.brInput :global .MuiInputBase-root.Mui-disabled,
.brInput :global .MuiInputBase-root .Mui-disabled {
  border-radius: 20px;
}

.smInput :global .MuiInputBase-root .MuiInputBase-input {
  padding: 2px 10px !important;
}

.whiteIp :global .MuiInputBase-root {
  color: white;
}

.whiteIpStandard :global .MuiInput-root:after {
  border-bottom: 1px solid white;
}

.autoCompleteIp :global .MuiInputBase-root-MuiInput-root::before {
  display: none;
}

/* ========= SELECT DROPDOWN ======== */
.selectPaperIp>div,
.selectPaperIp li {
  font-size: var(--input-fs);
}

.selectPaperIp ul {
  max-height: 200px;
}

/* ========= SNACKBAR ======== */

.errorSnackbar {
  background-color: #D021391C;
  border: 1px solid #D021393D;
  box-shadow: none;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.errorSnackbarMessage,
.successSnackbarMessage,
.warningSnackbarMessage {
  color: #000000b5;
  font-weight: 400;
  font-size: 12px;
  padding: 2px 0
}

.warningSnackbar {
  background-color: #f99b371C;
  border: 1px solid #f99b37d6;
  box-shadow: none;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ========= TABS ======== */

.tabIcon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0;
  max-width: 18px;
  height: auto;
}

.tabIcon img {
  width: 100%;
}

/* ========= CUSTOM TABS ======== */
.customTabs {
  min-height: 30px;
  display: flex;
}

.tabFlexContainer {
  width: 100%;
}

/* ========= CUSTOM RADIO TABS ======== */

.radioTabContainerJustifty {
  justify-content: center;
  border-bottom: 1px solid rgb(192, 192, 192);
}

.radioTabBtn {
  display: none;
}

.radioTab {
  border-bottom: 2px solid transparent;
  text-transform: capitalize;
  min-height: 30px;
  min-width: 170px;
  display: flex;
  justify-content: center;
}

.radioTab:has(>.radioTabBtnChecked) {
  border-bottom: 2px solid var(--secondarygreen);
}

/* ========= CUSTOM RADIO TOGGLE ======== */

.radioToggleBtn {
  display: none;
}

.radioToggleBtnChecked {
  background-color: var(--primaryblue);
  color: white;
  border-radius: inherit;
  /* padding: 4px;
  margin-top: 1px;
  margin-bottom: 1px; */
}

.radioToggleBtnChecked .radioToggleLabel {
  color: rgb(233, 233, 233);
  font-weight: 700;
}

.radioToggle .radioToggleLabel {
  font-size: 0.8rem;
}

.radioToggle {
  min-width: 100px;
  display: flex;
  justify-content: center;
  margin: 0 2px;
  padding: 2px 16px;
}

.radioToggleGroup {
  position: relative;
  width: max-content;
  background-color: lightgrey;
  border-radius: 16px;
}

/* .radioToggleGroup:before {
  content: '';
  position: absolute;
  width: calc(100% / 3);
  height: 100%;
  left: 0px;
  background-color: var(--secondarygreen);
  top: 0;
  border-radius: var(--headerbr);
  z-index: -1;
} */


/* ========= TABLE(WITH BORDER) ======== */
.tableWrapper {
  position: relative;
}

.tableWrapper:after {
  content: '';
  background-color: var(--greybkg);
  position: absolute;
  width: 12px;
  height: calc(100% - 0px);
  right: -14px;
  bottom: 0;
  top: 0;
  left: auto;
  z-index: 2;
}

.tableOuter {
  overflow: scroll;
  padding-right: 14px;
  margin-right: -24px;
  scrollbar-width: thin;
  scrollbar-color: #aaa transparent;
}

.tableOuter:has(.overlay) {
  min-height: 300px;
}

.borderedRow :global .MuiDataGrid-cell {
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
}

.borderedRow :global .MuiDataGrid-cell:first-child {
  border-left: 1px solid lightgray;
  border-radius: var(--tbbr) 0 0 var(--tbbr);
}

.borderedRow :global .MuiDataGrid-cell:last-child {
  border-right: 1px solid lightgray;
  border-radius: 0 var(--tbbr) var(--tbbr) 0;
}

.customTable .inValidRow {
  border: 1px solid var(--redText);
  outline: 1px solid var(--redText);
  background-color: rgb(236, 187, 187);
}

.customTable .deletedRow {
  background-color: rgb(224, 224, 224);
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
  border: 1px solid var(--greybkg);
  outline: 1px solid var(--greybkg);
  filter: grayscale(1);
}

/* ========= CUSTOM TABLE ======== */

.cellTextWrap,
.hideRightSeparator,
.disabledRow,
.colEllipse {
  /* DO NOT DELETE */
}

.tableIp :global .MuiInputBase-input {
  padding: 4px 6px !important;
}

.smTblSpacing :global .MuiDataGrid-row .MuiDataGrid-cell,
.smTblSpacing :global .MuiDataGrid-columnHeaderTitleContainer {
  padding: 6px 8px !important;
}

.anayTable :global .MuiDataGrid-main {
  background-color: white;
  font-size: 12px;
  border: 1px solid rgb(198 198 198);
}

.anayTable :global .MuiDataGrid-root .MuiDataGrid-withBorderColor {
  border-color: rgb(198 198 198);
}

.anayTable :global .MuiDataGrid-columnHeaderTitle {
  text-transform: uppercase;
}

.nestedhead {
  /* border-bottom: 2px solid #59b362; */
  /* border-right: 40px solid #092b4a;
  border-left: 40px solid #092b4a; */
}

/* ========= LABEL ======== */
.labelCheckboxContainer :global .MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-size: 0.6rem;
  font-weight: 600;
}

.ipLabel {
  overflow: visible;
  text-overflow: unset;
  margin: auto 10px auto 0;
}

.whiteLabel {
  color: white;
}

.radioLabel {
  border-bottom: 1px solid #c8c8c8;
  padding-bottom: 4px;
}

/* ========= LINK ======== */
.link {
  color: var(--primaryblue);
  cursor: pointer;
}

.link:hover {
  color: var(--secondarygreen);
  text-decoration: underline;
}

.linkNoUnderline {
  text-decoration: none;
}

/* ======= */

.overflowAutoWrapper {
  overflow: auto;
  padding-right: var(--gap);
  margin-right: calc(var(--gap) * -1);
}

/* ========= OVERLAY ======== */
.cardOverlay {
  position: relative;
  border-radius: 20px;
  filter: grayscale(1);
  opacity: 0.8;
}

.cardOverlay:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(215, 215, 215, 0.5);
  z-index: 1;
  top: 0;
  left: 0;
  border-radius: inherit;
}

.disableIconBtn,
.disableIconBtn img.pointer {
  opacity: 0.5;
  /* cursor: not-allowed; */
  pointer-events: none;
}

@media screen and (max-width: 1024px) {
  /* .tableOuter {
    margin-right: 0
  } */

  .tableWrapper:after {
    width: 16px;
    right: -16px;
  }
}

@media screen and (max-width: 900px) {
  .contentContainer {
    margin-top: calc(var(--headerheight) + (var(--splayout)*2));
  }

  .headerFieldContainer {
    left: auto;
    right: 50px;
    max-width: min-content;
    width: auto;
  }
}

@media screen and (max-width: 767px) {

  .headerFieldContainer {
    position: static;
    background-color: var(--greybkg);
    padding: 12px;
    border-radius: var(--headerbr);
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    margin: var(--splayout) 0;
  }
}

@media screen and (max-width: 1199px) {
  .mdW100 {
    width: 100%;
  }
}

@media screen and (max-width: 899px) {
  .smW100 {
    width: 100%;
  }
}

@media screen and (max-width: 599px) {
  .xsW100 {
    width: 100%;
  }
}


/* ============================== CSS Styles ============================== */


/* ========== content-alignment =========== */
.dBlock {
  display: block !important;
}

.dInlineBlock {
  display: inline-block !important;
}

.vHidden {
  visibility: hidden;
}

.dInlineFlex {
  display: inline-flex !important;
}

.dFlex {
  display: flex !important;
}

.fvMiddle {
  display: flex !important;
  align-items: center;
}

.fMiddle {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.fSpaceBtw {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}

.fStartSpaceBtw {
  display: flex !important;
  justify-content: space-between;
  align-items: flex-start;
}

.fEvenly {
  display: flex;
  justify-content: space-evenly;
}

.fJcAround {
  display: flex;
  justify-content: space-around;
}

.fColumn {
  display: flex !important;
  flex-direction: column;
}

.fRow {
  display: flex !important;
  flex-direction: row;
}

.jcCenter {
  justify-content: center;
}

.fAlignEnd {
  display: flex !important;
  align-items: flex-end;
}

.fJustifyEnd {
  display: flex !important;
  justify-content: flex-end;
}

.fEvenly {
  display: flex !important;
  justify-content: space-evenly;
}

.fColumnEnd {
  display: flex !important;
  flex-direction: column;
  align-items: flex-end;
}

.fColumnjcEnd {
  display: flex !important;
  flex-direction: column;
  justify-content: flex-end;
}

.fFirst {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.fLast {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.flexWrap {
  flex-wrap: wrap;
}

.flexNoWrap {
  flex-wrap: nowrap;
}

.dFlexAuto {
  display: flex !important;
  flex: auto;
}

.alignSelfStart {
  align-self: flex-start;
}

.dFlexGrow1 {
  flex-grow: 1;
}

.gap10 {
  gap: 10px;
}

.gap20 {
  gap: 20px;
}

.fAuto {
  flex: auto;
}

.fZero {
  flex: 0;
}

.fOne {
  flex: 1;
}

.floatRight {
  float: right;
}

.floatLeft {
  float: left;
}

/* ========== font-size =========== */
.fs0 {
  font-size: 0;
}

.fs10 {
  font-size: 10px;
}

.fs12 {
  font-size: 12px;
}

.fs14 :global .MuiInputBase-root {
  font-size: 14px;
}

.textCenter {
  text-align: center;
}

.textRight {
  text-align: right;
}

.textLeft {
  text-align: left;
}

.textUppercase {
  text-transform: uppercase;
}

.textLowercase {
  text-transform: lowercase;
}

/* ========== width & height =========== */

.wAuto {
  width: auto;
}

.w100 {
  width: 100%;
}

.w80 {
  width: 80%;
}

.h100 {
  height: 100%;
}

.width170 {
  width: 170px;
}

.width130 {
  width: 130px;
}

.width245 {
  width: 245px;
}

.minWidth40 {
  min-width: 40px !important;
}

.minWidth60 {
  min-width: 60px !important;
}

.minWidth80 {
  min-width: 80px !important;
}

.minWidth90 {
  min-width: 90px !important;
}

.minWidth100 {
  min-width: 100px !important;
}

.minWidth125 {
  min-width: 125px !important;
}

.minWidth128 {
  min-width: 128px !important;
}

.minWidth135 {
  min-width: 135px !important;
}

.minWidth145 {
  min-width: 145px !important;
}

.minWidth150 {
  min-width: 150px !important;
}

.minWidth170 {
  min-width: 170px !important;
}

.minWidth190 {
  min-width: 190px !important;
}

.minWidth205 {
  min-width: 205px !important;
}

.minWidth280 {
  min-width: 280px !important;
}

.maxWidth200 {
  max-width: 200px !important;
}

.maxWidth100 {
  max-width: 100px !important;
}

/* ========== position =========== */
.positionRelative {
  position: relative;
}

.positionStatic {
  position: static;
}

/* ========== cursor =========== */
.pointer {
  cursor: pointer;
}

.pointerNone {
  pointer-events: none;
}

/* ========== color =========== */
.colorPrimary {
  color: var(--primaryblue);
}

.colorSecondary {
  color: var(--secondarygreen);
}

.colorRed {
  color: var(--redText);
}

.colorTransparent {
  color: transparent;
}

.colorPlaceholder {
  color: #353535;
}


/* ========== wrap & overflow =========== */
.spaceNowrap {
  white-space: nowrap;
}

.overflowAuto {
  overflow: auto;
}

.overflowBreak {
  overflow-wrap: break-word;
}

/* ========== padding & margin =========== */

.m0 {
  margin: 0 !important;
}

.m1 {
  margin: .25rem !important;
}

.m2 {
  margin: .5rem !important;
}

.m3 {
  margin: 1rem !important;
}

.m4 {
  margin: 1.5rem !important;
}

.m5 {
  margin: 3rem !important;
}

.mt0 {
  margin-top: 0 !important;
}

.mr0 {
  margin-right: 0 !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.ml0 {
  margin-left: 0 !important;
}

.mx0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.my0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.mt1 {
  margin-top: .25rem !important;
}

.mr1 {
  margin-right: .25rem !important;
}

.mb1 {
  margin-bottom: .25rem !important;
}

.ml1 {
  margin-left: .25rem !important;
}

.mx1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

.my1 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important;
}

.mt2 {
  margin-top: .5rem !important;
}

.mr2 {
  margin-right: .5rem !important;
}

.mb2 {
  margin-bottom: .5rem !important;
}

.ml2 {
  margin-left: .5rem !important;
}

.mx2 {
  margin-right: .5rem !important;
  margin-left: .5rem !important;
}

.my2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.mt3 {
  margin-top: 1rem !important;
}

.mr3 {
  margin-right: 1rem !important;
}

.mb3 {
  margin-bottom: 1rem !important;
}

.ml3 {
  margin-left: 1rem !important;
}

.mx3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.my3 {
  margin-bottom: 1rem !important;
  margin-top: 1rem !important;
}

.mt4 {
  margin-top: 1.5rem !important;
}

.mr4 {
  margin-right: 1.5rem !important;
}

.mb4 {
  margin-bottom: 1.5rem !important;
}

.ml4 {
  margin-left: 1.5rem !important;
}

.mx4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.my4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.mt5 {
  margin-top: 3rem !important;
}

.mr5 {
  margin-right: 3rem !important;
}

.mb5 {
  margin-bottom: 3rem !important;
}

.ml5 {
  margin-left: 3rem !important;
}

.mx5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.my5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.mtauto {
  margin-top: auto !important;
}

.mrauto {
  margin-right: auto !important;
}

.mbauto {
  margin-bottom: auto !important;
}

.mlauto {
  margin-left: auto !important;
}

.mxauto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.myauto {
  margin-bottom: auto !important;
  margin-top: auto !important;
}

.mauto {
  margin: auto !important;
}

.p0 {
  padding: 0 !important;
}

.p1 {
  padding: .25rem !important;
}

.p2 {
  padding: .5rem !important;
}

.p3 {
  padding: 1rem !important;
}

.p4 {
  padding: 1.5rem !important;
}

.p5 {
  padding: 3rem !important;
}

.pt0 {
  padding-top: 0 !important;
}

.pr0 {
  padding-right: 0 !important;
}

.pb0 {
  padding-bottom: 0 !important;
}

.pl0 {
  padding-left: 0 !important;
}

.px0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.py0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.pt1 {
  padding-top: .25rem !important;
}

.pr1 {
  padding-right: .25rem !important;
}

.pb1 {
  padding-bottom: .25rem !important;
}

.pl1 {
  padding-left: .25rem !important;
}

.px1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.py1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.pt2 {
  padding-top: .5rem !important;
}

.pr2 {
  padding-right: .5rem !important;
}

.pb2 {
  padding-bottom: .5rem !important;
}

.pl2 {
  padding-left: .5rem !important;
}

.px2 {
  padding-right: .5rem !important;
  padding-left: .5rem !important;
}

.py2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.pt3 {
  padding-top: 1rem !important;
}

.pr3 {
  padding-right: 1rem !important;
}

.pb3 {
  padding-bottom: 1rem !important;
}

.pl3 {
  padding-left: 1rem !important;
}

.py3 {
  padding-bottom: 1rem !important;
  padding-top: 1rem !important;
}

.px3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.pt4 {
  padding-top: 1.5rem !important;
}

.pr4 {
  padding-right: 1.5rem !important;
}

.pb4 {
  padding-bottom: 1.5rem !important;
}

.pl4 {
  padding-left: 1.5rem !important;
}

.px4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.py4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.pt5 {
  padding-top: 3rem !important;
}

.pr5 {
  padding-right: 3rem !important;
}

.pb5 {
  padding-bottom: 3rem !important;
}

.pl5 {
  padding-left: 3rem !important;
}

.px5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}



.mtNeg24 {
  margin-top: -24px !important;
}

.mtNeg12 {
  margin-top: -12px !important;
}

.mbNeg12 {
  margin-bottom: -12px !important;
}

.mrNeg12 {
  margin-right: -12px !important;
}

.mxNeg24 {
  margin-right: -24px !important;
  margin-left: -24px !important;
}

.pr24 {
  padding-right: 24px !important;
}