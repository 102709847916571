.awbTable {
  height: calc(var(--contentBox) - 110px);
}

.dataLabel,
.data {
  display: inline-block;
}

.dataLabel {
  min-width: 90px;
  text-transform: uppercase;
  margin-bottom: 0px;
  font-weight: 700;
  font-size: 11px;
  color: gray;
}

/* .green {
  color: var(--secondarygreen);
} */

.capsule {
  padding: 3px;
  text-align: right;
  border-radius: 11px;
  margin-right: 20px;
}
/* .green {
  background-color: #19a4190a;
  color: #178e06;
  border: 1px solid #178e06;
}
.red {
  background-color: #ff05050a;
  color: #9c0000;
  border: 1px solid #9c0000;
}
.blue {
  background-color: #0739c40a;
  color: #3a0cc4;
  border: 1px solid #3a0cc4;
}
.orange {
  background-color: #ff8c000a;
  color: #ff8c00;
  border: 1px solid #ff8c00;
} */
.green {
  background-color: #178e06;
  color: #178e06;
  border: 1px solid #178e06;
}
.red {
  background-color: darkgray;
  color: darkgray;
  /* border: 1px solid #9c0000; */
}
.blue {
  background-color: #3a0cc4;
  color: #3a0cc4;
  border: 1px solid #3a0cc4;
}
.orange {
  background-color: #ff8c00;
  color: #ff8c00;
  border: 1px solid #ff8c00;
}
.chip {
  padding: 4px 12px;
  text-align: center;
  border-radius: 5px;
  margin-right: 20px;
  /* background: #eaeaea; */
  /* color: var(--primaryblue); */
  font-weight: 600;
  min-width: 150px;
}
.dot {
  /* padding: 4px 12px; */
  text-align: center;
  border-radius: 50px;
  margin-right: 10px;
  /* background: #eaeaea; */
  /* color: var(--primaryblue); */
  font-weight: 600;
width: 8px;
height: 8px;
display: inline-block;
}


.addNote {
  /* background-color: var(--primaryblue); */
  border-radius: 4px;
  padding: 2px 0px;
  padding-left: 6px;
  margin-top: -12px;
}

.addNote svg {
  font-size: 33px;
}