.logTableOuter {
  height: 400px;
  /* height: 600px; */
}

.fixedSecond {
  position: sticky;
  left: 50px;
  z-index: 3;
  background-color: inherit;
}

.fixedFirst {
  position: sticky;
  left: 0;
  z-index: 3;
  background-color: inherit;
  border-radius: inherit;
}

.logTable .fixedSecondHead {
  position: sticky;
  left: 50px;
  z-index: 3;
  background-color: var(--primaryblue);
}

.logTable .fixedFirstHead {
  position: sticky;
  left: 0;
  z-index: 3;
  background-color: var(--primaryblue);

}


.customAccordion:first-of-type,
.customAccordion:last-of-type,
.customAccordion {
  border-radius: 12px;
  box-shadow: none;
}

.customAccordion {
  background-color: #f9f9f9;
}

.saveBtn {
  margin-left: auto;
  margin-top: 20px;
}

.logStatus {
  display: flex;
  align-items: center;
  font-weight: 600;
  padding-left: 6px;
  margin-top: 3px;
  margin-left: auto;
  margin-right: 20px;
}

.statusTag {
  background-color: #f2f2f2;
  padding: 1px 9px;
  border-radius: 10px;
  color: orange;
  font-weight: 700;
  background-color: antiquewhite;
  /* text-transform: uppercase; */
  text-align: center;
  border: 1px solid orange;
  font-size: 11px;
}

.accordionHead {
  border-left: 3px solid var(--secondarygreen);
  padding-left: 12px;
}

.expanded .accordionSummary {
  border-bottom: 1px solid #eee;
  margin-bottom: 11px;
}

.expanded {
  margin: 0;
}

.statsWrapperLog {
  max-height: 330px;
}

.addTextFieldStats {
  width: 200px;
  padding-left: 10px;
}

.labelStats {
  width: calc(100% - 200px);
}

.overflowWrapper {
  overflow-y: scroll;
  overflow-x: auto;
  padding-right: calc(var(--gap) - 14px);
  /* gap - scrollbarwidth */
  margin-right: calc(var(--gap) * -1);
}

.overflowWrapper::-webkit-scrollbar-track {
  background-color: var(--greybkg);
}

.overflowWrapper ::-webkit-scrollbar-track {
  background-color: var(--greybkg);
}

.inputLogWrapper .inputLogRoot {
  padding-left: 10px;
  padding-right: 10px;
}

.inputLogWrapper .inputLogRoot .inputLogInput,
.inputLogWrapper .inputLogRoot .inputLogInput:focus,
.inputLogWrapper .inputLogRoot .inputLogInput:hover {
  border-left: 1px solid lightgrey;
  border-radius: 0;
  padding: 0 14px;
  margin: 6px 0;
}

.inputLogRoot .inputLogInput.inputLogDisabled + .inputAdornedEnd .badge {
  color: white;
  -webkit-text-fill-color: rgb(255, 255, 255);
}

/* table layout */
.minIpWidth {
  min-width: 100px;
  max-width: 180px;
}

.colEllipse div {
  /* change */
  white-space: nowrap !important;
  /* change */
  overflow: hidden;
  text-overflow: ellipsis !important;
  /* change */
}

.checkdetails {
  line-height: 1.5;
  text-wrap: balance;
}

/* pending items */
/* .itemCount {
  background-color: var(--secondarygreen);
  height: 24px;
  min-width: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 12px;
  font-weight: 800;
}

.statusDot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: red;
}

.historyListItem {
  background-color: #0000000f;
} */